import React, { useMemo } from "react";
import Select from "react-select";
import countryList from "react-select-country-list";

interface LocationDropdownProps {
  label: string;
  onChange: (value: string) => void;
}

export const LocationDropdown: React.FC<LocationDropdownProps> = (
  {
    label,
    onChange,
  }
) => {
  const options = useMemo(() => countryList().getData(), []);

  const handleSelectChange = (selectedValue: any) => {
    if (selectedValue) {
      onChange(selectedValue.value);
    }
  };

  return <div className="form-group">
    <label htmlFor="location">{label}</label>
    <Select onChange={handleSelectChange} options={options} />
  </div>
};
