import { IClientPasswordReset, IPlan, IUpdateProfile } from 'types/interfaces';
import api from '../config/apiConfig';

const fetchUserEsims = (email: string) => {
    const route = `/esims/all/${email}`;
    return api.get(route);
};

const fetchEsimDetails = (iccid: string) => {
    const route = `/esims/${iccid}`;
    return api.get(route);
};

const fetchClientEsims = (params: object) => {
    const route = `/esims/`;
    return api.get(route, { params });
};

const fetchSingleClientEsims = (iccid: string) => {
    const route = `/esims/${iccid}`;
    return api.get(route);
};

const fetchSubscriptionDetails = (subscription_id: string) => {
    const route = `/subscriptions/${subscription_id}`;
    return api.get(route);
};

const fetchSubscriptions = (params: object) => {
    const route = `/subscriptions`;
    return api.get(route, { params });
};

const activateSubscription = (subscription_id: string) => {
    const route = `/subscriptions/${subscription_id}/activate`;
    return api.put(route);
};

const expireSubscription = (subscription_id: string) => {
    const route = `/subscriptions/${subscription_id}/expire`;
    return api.put(route);
};

const refundSubscription = (subscription_id: string) => {
    const route = `/subscriptions/${subscription_id}/refund`;
    return api.put(route);
};

const getSalesValue = (params: object) => {
    const route = `/subscriptions/sales`;
    return api.get(route, { params });
};

const fetchOrderDetails = (order_id: string) => {
    const route = `/orders/${order_id}`;
    return api.get(route);
};

const fetchOrders = (params: object) => {
    const route = `/orders`;
    return api.get(route, { params });
};

const createOrder = (
    firstName: string,
    lastName: string,
    email: string,
    countryOfResidence: string,
    id: string,
    OS: string,
    model: string,
    productCode: string,
    orderCountry: string,
    activationDate: string,
    operationType: string,
    isFromOldB2b: boolean = true
) => {
    const now = new Date();
    const fiveMinutesLater = new Date(now.getTime() + 5 * 60 * 1000);
    const route = '/orders';
    const body = {
        subscriber: {
            firstName,
            lastName,
            email,
            countryOfResidence,
        },
        deviceInfo: {
            id,
            OS,
            model,
        },
        productCode,
        orderCountry,
        activation_date: fiveMinutesLater.toISOString(),
        operationType,
        isFromOldB2b
    };
    return api.post(route, body);
};

const fetchContinent = () => {
    const route = '/destinations';
    return api.get(route);
};

const searchForDestination = (name: string) => {
    const route = `/destinations/search/${name}`;
    return api.get(route);
};

const fetchPlansCatalogForCountry = (footprint: string) => {
    const route = `/destinations/${footprint}`;
    return api.get(route);
};

const fetchAllZimPlans = () => {
    const route = `/products/plans?page=-1`;
    return api.get(route);
};

const getClient = () => {
    const route = '/client';
    return api.get(route);
};

const resetClientPassword = (body: IClientPasswordReset) => {
    const route = '/client/password/reset';
    return api.put(route, body);
};

const updateClientProfile = (body: IUpdateProfile) => {
    const route = '/client/profile/update';
    return api.patch(route, body);
};

const getAllClients = () => {
    const route = '/admin/clients';
    return api.get(route);
};

const getAdmin = () => {
    const route = '/admin';
    return api.get(route);
};

const reportIssue = (body: object) => {
    const route = '/client/report-issue';
    return api.post(route, body);
};

const getClientBillingCsv = (body: object) => {
    const route = `/admin/clients/billing`;
    return api.post(route, body);
};

const fetchReports = () => {
    const route = `/admin/clients/reports`;
    return api.get(route);
};

const closeReport = (id: string) => {
    const route = `/admin/clients/reports/${id}`;
    return api.patch(route);
};

const fetchUnusedSubscriptions = () => {
    const route = `/admin/get-unused-esimgo-subscriptions`;
    return api.get(route);
};


const revokeUnusedSubscription = (simId: string, providerSubscriptionId: string) => {
    console.log({ simId, providerSubscriptionId });

    const route = `/admin/revoke-unused-esimgo-subscription`;
    return api.post(route, { simId, providerSubscriptionId });
};

const updateSubscription = (subscriptionId: string, firstName: string, lastName: string) => {
    const route = `/subscriptions/${subscriptionId}`;
    const body = {
        subscriberFirstName: firstName,
        subscriberLastName: lastName
    }
    return api.put(route, body);
}


const getPlans = (params: object) => {
    const route = `/products/plans`;
    return api.get(route, { params });
};

const searchPlans = (name: string) => {
    const route = `/products/plans/search/${name}`;
    return api.get(route);
};

const editPlan = (plan: IPlan) => {
    const route = `/products/plans/${plan.productCode}`;
    return api.put(route, { plan });
};

const insertOrangeEsim = (body: object) => {
    const route = `/products/insert-orange-esims`;
    return api.post(route, body);
};

const insertWtrEsim = (body: object) => {
    const route = `/products/insert-transatel-esims`;
    return api.post(route, body);
};

const topupSubscription = (subscriptionId: string) => {
    const route = `/orders/topup?subscriptionId=${subscriptionId}`;
    return api.post(route);
};

export const apiEndpoints = {
    fetchUserEsims,
    fetchEsimDetails,
    fetchClientEsims,
    fetchSubscriptions,
    fetchSubscriptionDetails,
    activateSubscription,
    expireSubscription,
    getSalesValue,
    fetchOrderDetails,
    fetchOrders,
    createOrder,
    fetchContinent,
    searchForDestination,
    fetchPlansCatalogForCountry,
    fetchAllZimPlans,
    getClient,
    resetClientPassword,
    updateClientProfile,
    getAllClients,
    getAdmin,
    reportIssue,
    getClientBillingCsv,
    fetchReports,
    closeReport,
    updateSubscription,
    getPlans,
    editPlan,
    searchPlans,
    insertOrangeEsim,
    insertWtrEsim,
    fetchSingleClientEsims,
    topupSubscription,
    refundSubscription,
    fetchUnusedSubscriptions,
    revokeUnusedSubscription
};
