/* eslint-disable no-param-reassign */
import axios from 'axios';
import { Secrets } from 'services/secrets';
import { authApi } from '../endpoints/authEndpoints';

const baseURL = Secrets.zimServicesUrl
const api = axios.create({ baseURL });

api.interceptors.request.use(
	(config) => {
		try {
			const token = localStorage.getItem("access_token");
			if (config.headers) {
				config.headers.Accept = "application/json";
				if (token) {
					config.headers.Authorization = `Bearer ${token}`;
				}
			}
			return config;
		} catch (error: any) {
			return undefined;
		}
	},
	(error) => Promise.reject(error)
);

api.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => new Promise((resolve, reject) => {
		const originalRequest = error.config;
		if (
			error.response.status === 403 &&
			error.response.data.message === 'jwt expired'
		) {
			refreshTokens()
				.then((response) => {
					localStorage.setItem('access_token', response.data.access_token);
					localStorage.setItem('refresh_token', response.data.refresh_token);
					resolve(api(originalRequest));
				})
				.catch((error) => {
					if (
						(error.response.status === 400 || error.response.status === 403) && error.response.data.message === "jwt expired" ||
						(error.response.status === 400 || error.response.status === 401) && error.response.data.message == "Refresh token is expired"
					) {
						// redirect to login page
						localStorage.clear();
						window.location.href = '/auth/login';
					}
					reject(error);
				});
		} else {
			reject(error);
		}
	})
);

async function refreshTokens() {
	const client_id = localStorage.getItem('client_id') as string;
	const refresh_token = localStorage.getItem('refresh_token') as string;
	const grant_type = 'refresh_token';
	return await authApi.refreshToken(client_id, refresh_token, grant_type);
}

export default api;
