import { useState, useEffect, useRef, useCallback } from 'react';
import {
	Card,
	CardHeader,
	CardFooter,
	CardBody,
	Table,
	Container,
	Row,
	Form,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Button,
	UncontrolledAlert,
} from 'reactstrap';
import Header from 'components/Headers/Header';
import { apiEndpoints } from '../../services/api/endpoints/apiEndpoints';
import Pagination from '../../components/Pagination/Pagination';
import Select from 'react-select';
import { useStateContext } from 'contexts/contextProvider';
import PropagateLoader from "react-spinners/PropagateLoader";
import { EsimProvider } from 'types/enums';
import { IPlan } from 'types/interfaces';
import { countryNameToIsoCode } from "../../utils";

const override = {
	display: "block",
	margin: "0 auto",
	borderColor: "red",
};

export const Orders = (props: any) => {
	const orderCreationInfoInit = Object.freeze({
		firstName: '',
		lastName: '',
		email: '',
		countryOfResidence: 'SA',
		id: generateRandomString(),
		OS: '',
		model: '',
		orderCountry: 'SA',
		productCode: '',
		activationDate: '',
		operationType: 'ACTIVATION',
	});
	const [orderCreationInfo, setOrderCreationInfo] = useState(
		orderCreationInfoInit
	);
	const { setOrders, setUser, user } = useStateContext();
	const [orders, setComponentOrders] = useState([]);
	const [options, setOptions] = useState<any[]>([]);
	const [supportedCountries, setSupportedCountries] = useState<any[]>([]);
	const [page, setPage] = useState(1);
	const [isLoading, setIsloading] = useState<boolean>(true);
	const [errorMsg, setErrorMsg] = useState<string>();
	const allPages = useRef<number[]>([]);
	const itemsPerPage = useRef(10);
	const [isAramco, setIsAramco] = useState(true)
	const chunkSize = Math.ceil(supportedCountries.length / 3);
	const rows = [];

	const getOrders = useCallback(
		async () => {
			try {
				const params = {
					pageSize: itemsPerPage.current,
					page,
				};
				const response = await apiEndpoints.fetchOrders(params);

				const pages = [];
				for (
					let i = 1;
					i <= Math.ceil(response.data.total / itemsPerPage.current);
					i++
				) {
					pages.push(i);
				}

				allPages.current = pages;
				setComponentOrders(response.data.orders);
				setPage(response.data.page);
				setIsloading(false)
			} catch (error) { }
		},
		[page]
	);

	const noOptionLoaderComponent = () => {
		return <div className="d-flex justify-content-center align-items-center">
			<div className="spinner-border text-primary" role="status">
				<span className="sr-only">Loading...</span>
			</div>
		</div>;
	}

	async function fetchPlans() {
		try {
			const response = await apiEndpoints.fetchAllZimPlans();
			let esimPlans = response.data.data;
			const customForClientIdPlans = [];
			const restPlans: IPlan[] = [];
			if (isAramco) {

				for (const plan of esimPlans) {
					if (plan.clientId) {
						customForClientIdPlans.push(plan);
					} else if (plan.provider === EsimProvider.ESIMGO) {
						restPlans.push(plan);
					}
				}

				esimPlans = [...customForClientIdPlans, ...restPlans].filter((plan: IPlan) =>
					plan.clientId ||
					plan.data >= 5 ||
					["REGIONAL", "GLOBAL"].includes(plan.scope) ||
					(["ARE", "KWT", "SAU", "QAT", "BHR", "OMN"].includes(plan.footprintCode) && plan.data >= 3)
				).sort((a, b) => b.data - a.data);
			}

			const planOptions = esimPlans.map((plan: IPlan) => ({
				value: plan,
				label: plan.name.replace("Local", "") + " - " + plan.priceUsd.toFixed(2) + " $"
			}));

			setOptions(planOptions);
		} catch (error) {
			console.error("Error fetching plans:", error);
		}
	}


	function collectData(e: any) {
		if (e.target) {
			setOrderCreationInfo({
				...orderCreationInfo,
				[e.target.name.trim()]: e.target.value.trim(),
			});
		} else {
			console.log("e.value.supportedCountries : ", e.value.supportedCountries);

			setOrderCreationInfo({
				...orderCreationInfo,
				productCode: e.value.productCode,
			});
			if (e.value.supportedCountries) {
				return setSupportedCountries(e.value.supportedCountries)
			}
			return setSupportedCountries([])
		}
	}

	function generateRandomString(length: number = 12): string {
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		let result = '';

		for (let i = 0; i < length; i++) {
			const randomIndex = Math.floor(Math.random() * characters.length);
			result += characters[randomIndex];
		}

		return result;
	}

	async function handleSubmitOrder(e: any) {
		document.documentElement.scrollTop = 0;
		setIsloading(true)
		setErrorMsg('');
		e.preventDefault();
		try {
			const response = await apiEndpoints
				.createOrder(
					orderCreationInfo.firstName,
					orderCreationInfo.lastName,
					orderCreationInfo.email,
					orderCreationInfo.countryOfResidence,
					orderCreationInfo.id,
					orderCreationInfo.OS,
					orderCreationInfo.model,
					orderCreationInfo.productCode,
					orderCreationInfo.orderCountry,
					orderCreationInfo.activationDate,
					orderCreationInfo.operationType
				);
			setOrders([...orders, response.data.order])
			setIsloading(false)
			setErrorMsg('Order successfully created')
		} catch (error: any) {
			setIsloading(false)
			setErrorMsg(error.response.data.message || "Something went wrong please try again later")
		}
	}

	function alertError() {
		if (errorMsg) {
			return (
				<UncontrolledAlert className='alert-default' fade={false}>
					<span className='alert-inner--text'>{errorMsg}</span>
				</UncontrolledAlert>
			);
		}
	}

	const getUser = async () => {
		try {
			const response = await apiEndpoints.getClient()
			setUser({ ...response.data.client, isAdmin: false });
			setIsAramco(process.env.REACT_APP_ARAMCO_CLIENT_ID === user.clientId)
		} catch (error) { }
	}

	useEffect(() => {
		getOrders();
		getUser();
	}, [page, getOrders]);

	useEffect(() => {
		fetchPlans();
	}, [isAramco])

	for (let i = 0; i < supportedCountries.length; i += chunkSize) {
		const chunk = supportedCountries.slice(i, i + chunkSize);
		rows.push(chunk);
	}

	const flagStyle = {
		width: '50px',
		height: '30px',
		objectFit: 'cover'
	};

	return (
		<>
			<Header state={props.state} />
			{/* Page content */}
			{
				isLoading
					?
					<div className="loader">
						<PropagateLoader
							color={"#8965e0"}
							loading={isLoading}
							cssOverride={override}
							size={40}
							aria-label="Loading Spinner"
							data-testid="loader"
						/>
					</div>
					:
					<Container className='mt--7' fluid>
						{alertError()}
						{/* Table */}
						{/* {!isAramco && <Row>
							<div className='col'>
								<Card className='shadow'>
									<CardHeader className='border-0'>
										<h3 className='mb-0'>Orders</h3>
									</CardHeader>
									<Table className='align-items-center table-flush' responsive>
										<thead className='thead-light'>
											<tr>
												<th scope='col'>EMAIL</th>
												<th scope='col'>PROVIDER</th>
												<th scope='col'>STATUS</th>
												<th scope='col'>DATE</th>
												<th scope='col'>ESIM ICCID</th>
											</tr>
										</thead>
										<tbody>
											{orders.map((order: any) => {
												return (
													<tr>
														<th scope='row'>{order.userEmail}</th>
														<td>{order.provider}</td>
														<td>{order.status}</td>
														<td>{order.createdAt.split('T')[0]}</td>
														<td>{order.output.iccid}</td>
													</tr>
												);
											})}
										</tbody>
									</Table>
									<CardFooter className='py-4'>
										<nav aria-label='...'>
											<Pagination
												allPages={allPages.current}
												itemsPerPage={itemsPerPage.current}
												page={page}
												setPage={setPage}
											/>
										</nav>
									</CardFooter>
								</Card>
							</div>
						</Row>
						} */}

						<Row>
							<div className='col'>
								<Card className='shadow mt-3'>
									<CardBody className='px-lg-5 py-lg-5'>
										<div className='text-center text-muted mb-4'>
											<small>Create new order</small>
										</div>
										<Form role='form' onSubmit={handleSubmitOrder}>
											<FormGroup className='mb-3 mb-4'>
												<small className='text-muted'>Subscriber Info</small>
												<InputGroup className='input-group-alternative'>
													<InputGroupAddon addonType='prepend'>
														<InputGroupText>
															<i className='ni ni-circle-08' />
														</InputGroupText>
													</InputGroupAddon>
													<Input
														placeholder='First name'
														type='text'
														autoComplete='new-user'
														name='firstName'
														onChange={collectData}
														required
													/>
													<InputGroupAddon addonType='prepend'>
														<InputGroupText>
															<i className='ni ni-circle-08' />
														</InputGroupText>
													</InputGroupAddon>
													<Input
														placeholder='Last name'
														type='text'
														autoComplete='new-user'
														name='lastName'
														onChange={collectData}
														required
													/>
													<InputGroupAddon addonType='prepend'>
														<InputGroupText>
															<i className='ni ni-email-83' />
														</InputGroupText>
													</InputGroupAddon>
													<Input
														placeholder='Email'
														type='email'
														autoComplete='new-email'
														name='email'
														onChange={collectData}
														required
													/>
												</InputGroup>
											</FormGroup>
											{/* <FormGroup className='mb-3'>
													<InputGroup className='input-group-alternative'>
														<InputGroupAddon addonType='prepend'>
															<InputGroupText>
																<i className='ni ni-email-83' />
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder='Email'
															type='email'
															autoComplete='new-email'
															name='email'
															onChange={collectData}
														/>
														<InputGroupAddon addonType='prepend'>
															<InputGroupText>
																<i className='ni ni-pin-3' />
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder='Country of residence'
															type='text'
															autoComplete='Country of residence'
															name='countryOfResidence'
															onChange={collectData}
														/>
													</InputGroup>
												</FormGroup> */}

											<FormGroup className='mb-3'>
												<small className='text-muted'>Device Info</small>
												<InputGroup className='input-group-alternative'>
													{/* <InputGroupAddon addonType='prepend'>
															<InputGroupText>
																<i className='ni ni-single-02' />
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder='Device ID'
															type='text'
															autoComplete='Device ID'
															name='id'
															onChange={collectData}
															className='stretch'
															required
														/> */}
													<InputGroupAddon addonType='prepend'>
														<InputGroupText>
															<i className='ni ni-button-power' />
														</InputGroupText>
													</InputGroupAddon>
													<select
														aria-label='.form-select-lg example'
														name='OS'
														onChange={collectData}
														className='stretch form-select form-select-lg mb-3'
														required
													>
														<option value='ACTIVATION' selected>
															Select Operation System
														</option>
														<option value='IOS'>IOS</option>
														<option value='ANDROID'>ANDROID</option>
													</select>
													<InputGroupAddon addonType='prepend'>
														<InputGroupText>
															<i className='ni ni-tag' />
														</InputGroupText>
													</InputGroupAddon>
													<Input
														required
														placeholder='Device model ex: Iphone 13 pro'
														type='text'
														autoComplete='model'
														name='model'
														onChange={collectData}
														className='stretch'
													/>
													<InputGroupAddon addonType='prepend'>
														<InputGroupText>
															<i className='ni ni-archive-2' />
														</InputGroupText>
													</InputGroupAddon>
													<Select
														required
														options={options}
														placeholder={'Select Plan'}
														className={'select'}
														onChange={collectData}
														components={{ NoOptionsMessage: noOptionLoaderComponent }}
													/>
												</InputGroup>
											</FormGroup>

											{/* <FormGroup className='mb-3 mb-4'>
													<small className='text-muted'>More Info</small>
													<InputGroup className='input-group-alternative'>
														<InputGroupAddon addonType='prepend'>
															<InputGroupText>
																<i className='ni ni-archive-2' />
															</InputGroupText>
														</InputGroupAddon>
														<Select
															required
															options={options}
															placeholder={'Select Plan'}
															className={'select'}
															onChange={collectData}
														/>
														<InputGroupAddon addonType='prepend'>
															<InputGroupText>
																<i className='ni ni-pin-3' />
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder='Order country'
															type='text'
															autoComplete='new-user'
															name='orderCountry'
															onChange={collectData}
															className='stretch'
														/>
													</InputGroup>
												</FormGroup> */}
											{/* <FormGroup className='mb-3'>
													<InputGroup className='input-group-alternative'>
														<InputGroupAddon addonType='prepend'>
															<InputGroupText>
																<i className='ni ni-time-alarm' />
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder='Activation date'
															type='date'
															autoComplete='new-activationDate'
															name='activationDate'
															onChange={collectData}
															className='stretch'
														/>
														<InputGroupAddon addonType='prepend'>
															<InputGroupText>
																<i className='ni ni-books' />
															</InputGroupText>
														</InputGroupAddon>
														<select
															aria-label='.form-select-lg example'
															name='operationType'
															onChange={collectData}
															className='stretch form-select form-select-lg mb-3'
														>
															<option value='ACTIVATION' selected>
																Select operation type
															</option>
															<option value='ACTIVATION'>Activation</option>
															<option value='TOPUP'>Topup</option>
														</select>
													</InputGroup>
												</FormGroup> */}

											<div className='text-center'>
												<Button className='my-4' color='primary' type='submit'>
													Create Order
												</Button>
											</div>
										</Form>
									</CardBody>
								</Card>
							</div>
						</Row>
						<Row>
							{supportedCountries.length > 0 && (
								<div className="p-5 shadow mt-3 card">
									<h2 className="mb-5 text-center">Supported Countries</h2>
									{rows.map((row, index) => (
										<div key={index} className="row mb-4">
											{row.map(country => (
												<div key={country.isoCode} className="col-md-4 d-flex flex-column align-items-center mb-3">
													{(() => {
														const countryName = (country.name || country) as keyof typeof countryNameToIsoCode;
														const isoCode = countryNameToIsoCode[countryName];

														if (isoCode) {
															return (
																<>
																	<img
																		src={`https://flagcdn.com/w320/${isoCode.toLowerCase()}.png`}
																		className="img-fluid mb-2"
																		style={{ maxWidth: '50px', height: 'auto', borderRadius: '5px' }}
																		alt={`${countryName} flag`}
																	/>
																	<p className="text-center font-weight-bold">{countryName}</p>
																</>
															);
														} else {
															console.warn(`ISO code not found for: ${countryName}`);
															return <p className="text-center text-muted">{countryName}</p>;
														}
													})()}
												</div>
											))}
										</div>
									))}
								</div>
							)}
						</Row>

					</Container>
			}
		</>
	);
};
