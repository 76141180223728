import {
	Button,
	Card,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Row,
	Col,
	UncontrolledAlert,
} from 'reactstrap';
import { useState } from 'react';
import { authApi } from 'services/api/endpoints/authEndpoints';
import { Link } from 'react-router-dom';

const data = Object.freeze({
	email: '',
	password: '',
});
export const Login = () => {
	const [userLoginInfo, setUserLoginInfo] = useState(data);
	const [errorMsg, setErrorMsg] = useState();

	function collectData(e: any) {
		setUserLoginInfo({
			...userLoginInfo,
			[e.target.name.trim()]: e.target.value.trim(),
		});
	}

	async function handleSubmit(e: any) {
		e.preventDefault();
		try {
			const response = await authApi.login(
				userLoginInfo.email,
				userLoginInfo.password
			);
			localStorage.setItem('access_token', response.data.accessToken);
			localStorage.setItem('refresh_token', response.data.refreshToken);
			localStorage.setItem('client_id', response.data.clientId);
			localStorage.setItem('isUserLoggedIn', 'true');
			window.location.reload();
		} catch (error: any) {
			setErrorMsg(error.response.data?.message);
		}
	}

	function alertError() {
		if (errorMsg) {
			return (
				<UncontrolledAlert className='alert-default' fade={false}>
					<span className='alert-inner--text'>{errorMsg}</span>
				</UncontrolledAlert>
			);
		}
	}

	return (
		<>
			<Col lg='5' md='7'>
				<Card className='bg-secondary shadow border-0'>
					<CardBody className='px-lg-5 py-lg-5'>
						<div className='text-center text-muted mb-4'>
							<small>Sign in with credentials to access the partners dashboard</small>
						</div>
						<Form role='form' onSubmit={handleSubmit}>
							<FormGroup className='mb-3'>
								<InputGroup className='input-group-alternative'>
									<InputGroupAddon addonType='prepend'>
										<InputGroupText>
											<i className='ni ni-email-83' />
										</InputGroupText>
									</InputGroupAddon>
									<Input
										placeholder='Email'
										type='email'
										autoComplete='new-email'
										name='email'
										onChange={collectData}
									/>
								</InputGroup>
							</FormGroup>
							<FormGroup>
								<InputGroup className='input-group-alternative'>
									<InputGroupAddon addonType='prepend'>
										<InputGroupText>
											<i className='ni ni-lock-circle-open' />
										</InputGroupText>
									</InputGroupAddon>
									<Input
										placeholder='Password'
										type='password'
										autoComplete='new-password'
										name='password'
										onChange={collectData}
									/>
								</InputGroup>
							</FormGroup>
							<div className='text-center'>
								<Button className='my-4' color='primary' type='submit'>
									Sign in
								</Button>
							</div>
						</Form>
					</CardBody>
				</Card>
				<Row className='mt-3'>
					<Col xs='6'>
						<Link to="/auth/password/reset">
							<small className='text-light'>Forgot password?</small>
						</Link>
					</Col>
				</Row>
				{alertError()}
			</Col>
		</>
	);
};
