let betaBackEndUrl = process.env.REACT_APP_PROD_BETA_BACKEND_URL;
let zimServicesUrl = process.env.REACT_APP_PROD_ZIM_SERVICES_URL;


if (process.env.REACT_APP_ENV_TYPE === 'development') {
    betaBackEndUrl = process.env.REACT_APP_LOCAL_BETA_BACKEND_URL;
    zimServicesUrl = process.env.REACT_APP_LOCAL_ZIM_SERVICES_URL
}

export const Secrets = {
    betaBackEndUrl,
    zimServicesUrl,
    ENV_TYPE: process.env.REACT_APP_ENV_TYPE
}