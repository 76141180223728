import { useState, useEffect, useRef } from 'react';
import {
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	DropdownToggle,
	Dropdown,
	Card,
	CardHeader,
	CardFooter,
	Table,
	Container,
	Row,
	UncontrolledAlert,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	Button,
	Form,
	FormGroup,
	Input,
	Label,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
} from 'reactstrap';
import Header from 'components/Headers/Header';
import { apiEndpoints } from '../../services/api/endpoints/apiEndpoints';
import { IPlan } from '../../types/interfaces';
import PropagateLoader from "react-spinners/PropagateLoader";
import { EsimProvider } from 'types/enums';
import { useStateContext } from 'contexts/contextProvider';

const override = {
	display: "block",
	margin: "0 auto",
	borderColor: "red",
};


interface Props {
	state: object;
}

export const Plans = (props: Props) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [filter, setFilter] = useState<string | null>(null);
	const [plans, setPlans] = useState<IPlan[]>([]);
	const [searchPlans, setSearchPlans] = useState<IPlan[]>([]);
	const [page, setPage] = useState(1);
	const [errorMsg, setErrorMsg] = useState<string>();
	const [isLoading, setIsloading] = useState<boolean>(true);
	const [editingPlan, setEditingPlan] = useState<any>(null);
	const [search, setSearch] = useState<string>('');
	const itemsPerPage = useRef(40);
	const { setUser } = useStateContext();

	async function getPlans() {
		try {
			setIsloading(true);
			setErrorMsg('');
			const params: any = {
				isForZim: true
			};
			const response = await apiEndpoints.getPlans(params);
			if (filter) {
				setPlans(response.data.data.filter((plan: IPlan) => plan.provider === filter));
			} else {
				setPlans(response.data.data);
			}
			setIsloading(false);
			paginate(1);
		} catch (error) {
			console.log("Error at getPlans()");
		}
	}

	function getSearchPlans() {
		setSearchPlans(plans.filter((plan: IPlan) => plan.name?.toLowerCase().includes(search)));
	}

	function changeFilter(provider: EsimProvider | null) {
		setSearch("");
		setFilter(provider);
	}

	function startEditingPlan(plan: IPlan) {
		setEditingPlan(plan);
	};

	function collectData(e: any) {
		setEditingPlan({
			...editingPlan,
			[e.target.name.trim()]: e.target.value.trim(),
		});
	}

	async function updatePlan() {
		try {
			setErrorMsg('');
			if (editingPlan) {
				const response = await apiEndpoints.editPlan(editingPlan);
				setEditingPlan(null);
				setPlans(plans.map((current: IPlan) => {
					if (current.productCode === editingPlan.productCode) {
						return response.data
					}
					return current
				}))
				setErrorMsg('Plan updated successfully');
			}
		} catch (error: any) {
			setErrorMsg(error.response.data?.message);
		}
	};

	function alertError() {
		if (errorMsg) {
			return (
				<UncontrolledAlert className='alert-default' fade={false}>
					<span className='alert-inner--text'>{errorMsg}</span>
				</UncontrolledAlert>
			);
		}
	}

	const getUser = async () => {
		try {
			const response = await apiEndpoints.getAdmin()
			setUser({ ...response.data.admin, isAdmin: true });
		} catch (error) { }
	}

	const paginate = (pageNumber: number) => setPage(pageNumber);
	const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

	const lastIndex = page * itemsPerPage.current;
	const firstIndex = lastIndex - itemsPerPage.current;
	const rangePlans = search ? searchPlans.slice(firstIndex, lastIndex) : plans.slice(firstIndex, lastIndex);
	const plansLength = search ? searchPlans.length : plans.length;

	useEffect(() => {
		getUser();
		getPlans();
	}, [filter])

	useEffect(() => {
		if (search) {
			getSearchPlans();
		}
	}, [search])

	return (
		<>
			<Header state={props.state} />
			{/* Page content */}
			{
				isLoading
					?
					<div className="loader">
						<PropagateLoader
							color={"#8965e0"}
							loading={isLoading}
							cssOverride={override}
							size={40}
							aria-label="Loading Spinner"
							data-testid="loader"
						/>
					</div>
					:
					<Container className='mt--7' fluid>
						{/* Table */}
						{alertError()}
						<Row>
							<div className='col'>
								<Card className='shadow'>
									<CardHeader className='border-0'>
										<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
											<h3 className='mb-0'>Plans</h3>
											<div style={{ display: 'flex', justifyContent: 'space-around' }}>
												<Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
													<DropdownToggle caret>
														{!filter ? "Provider" : filter}
													</DropdownToggle>
													<DropdownMenu right>
														<DropdownItem onClick={() => changeFilter(null)}>ALL</DropdownItem>
														<DropdownItem onClick={() => changeFilter(EsimProvider.ESIMGO)}>{EsimProvider.ESIMGO}</DropdownItem>
														<DropdownItem onClick={() => changeFilter(EsimProvider.TRUPHONE)}>{EsimProvider.TRUPHONE}</DropdownItem>
														<DropdownItem onClick={() => changeFilter(EsimProvider.ORANGE)}>{EsimProvider.ORANGE}</DropdownItem>
														<DropdownItem onClick={() => changeFilter(EsimProvider.WTR)}>{EsimProvider.WTR}</DropdownItem>
													</DropdownMenu>
												</Dropdown>
												<Form className="">
													<FormGroup className="mb-0" >
														<InputGroup className="input-group-alternative">
															<InputGroupAddon addonType="prepend">
																<InputGroupText style={{}}>
																	<i className="fas fa-search" />
																</InputGroupText>
															</InputGroupAddon>
															<Input
																placeholder="Search"
																type="text"
																onChange={e => setSearch(e.target.value.toLowerCase())}
															/>
														</InputGroup>
													</FormGroup>
												</Form>
											</div>


										</div>
									</CardHeader>
									<Table className='align-items-center table-flush' responsive>
										<thead className='thead-light'>
											<tr>
												<th scope='col'>Name</th>
												<th scope='col'>Product code</th>
												<th scope='col'>Provider</th>
												<th scope='col'>Data</th>
												<th scope='col'>Duration</th>
												<th scope='col'>Quantity</th>
												<th scope='col'>Price gbp</th>
												<th scope='col'>Price eur</th>
												<th scope='col'>Price usd</th>
												<th scope='col'>Flag code</th>
												<th scope='col'>Footprint code</th>
												<th scope='col' />
											</tr>
										</thead>
										<tbody>
											{rangePlans?.map((plan: IPlan) => {
												return (
													<tr>
														<th scope='row'>{plan.name}</th>
														<td>{plan.productCode}</td>
														<td>{plan.provider}</td>
														<td>{plan.data}</td>
														<td>{plan.duration}</td>
														<td>{plan.quantity}</td>
														<td>{plan.priceGbp}</td>
														<td>{plan.priceEur}</td>
														<td>{plan.priceUsd}</td>
														<td>{plan.flagCode}</td>
														<td>{plan.footprintCode}</td>
														<td className='text-right'>
															<UncontrolledDropdown>
																<DropdownToggle
																	className='btn-icon-only text-light'
																	href='#pablo'
																	role='button'
																	size='sm'
																	color=''
																	onClick={(e) => e.preventDefault()}
																>
																	<i className='fas fa-ellipsis-v' />
																</DropdownToggle>
																<DropdownMenu className='dropdown-menu-arrow' right>
																	<DropdownItem
																		onClick={() => startEditingPlan(plan)
																		}
																	>
																		EDIT
																	</DropdownItem>
																</DropdownMenu>
															</UncontrolledDropdown>
														</td>
													</tr>
												);
											})}
										</tbody>
									</Table>
									<CardFooter className='py-4'>
										<nav aria-label='...'>
											<nav>
												<ul className='pagination ml-3'>
													{Array.from({ length: Math.ceil(plansLength / itemsPerPage.current) }, (_, i) => (
														<li key={i} className={`page-item ${page === i + 1 ? 'active' : ''}`}>
															<a onClick={() => paginate(i + 1)} className='page-link'>
																{i + 1}
															</a>
														</li>
													))}
												</ul>
											</nav>
										</nav>
									</CardFooter>
								</Card>
							</div>
						</Row>

						<Modal isOpen={!!editingPlan} toggle={() => setEditingPlan(null)}>
							<ModalHeader toggle={() => setEditingPlan(null)}>Edit Plan</ModalHeader>
							<ModalBody>
								<Form>
									<FormGroup>
										<Label for="name">Name</Label>
										<Input
											type="text"
											id="name"
											value={editingPlan?.name}
											name="name"
											onChange={collectData}
										/>
									</FormGroup>
									<FormGroup>
										<Label for="provider">Provider</Label>
										<Input
											type="text"
											id="provider"
											value={editingPlan?.provider}
											name="provider"
											onChange={collectData}
										/>
									</FormGroup>
									<FormGroup>
										<Label for="productCode">Product Code</Label>
										<Input
											type="text"
											id="productCode"
											value={editingPlan?.productCode}
											name="productCode"
											onChange={collectData}
										/>
									</FormGroup>
									<FormGroup>
										<Label for="data">Data</Label>
										<Input
											type="text"
											id="data"
											value={editingPlan?.data}
											name="data"
											onChange={collectData}
										/>
									</FormGroup>
									<FormGroup>
										<Label for="duration">Duration</Label>
										<Input
											type="text"
											id="duration"
											value={editingPlan?.duration}
											name="duration"
											onChange={collectData}
										/>
									</FormGroup>
									<FormGroup>
										<Label for="quantity">Quantity</Label>
										<Input
											type="text"
											id="quantity"
											value={editingPlan?.quantity}
											name="quantity"
											onChange={collectData}
										/>
									</FormGroup>
									<FormGroup>
										<Label for="priceGbp">Price Gbp</Label>
										<Input
											type="text"
											id="priceGbp"
											value={editingPlan?.priceGbp}
											name="priceGbp"
											onChange={collectData}
										/>
									</FormGroup>
									<FormGroup>
										<Label for="priceEur">Price Eur</Label>
										<Input
											type="text"
											id="priceEur"
											value={editingPlan?.priceEur}
											name="priceEur"
											onChange={collectData}
										/>
									</FormGroup>
									<FormGroup>
										<Label for="priceUsd">Price Usd</Label>
										<Input
											type="text"
											id="priceUsd"
											value={editingPlan?.priceUsd}
											name="priceUsd"
											onChange={collectData}
										/>
									</FormGroup>
									<FormGroup>
										<Label for="footprintCode">Footprint Code</Label>
										<Input
											type="text"
											id="footprintCode"
											value={editingPlan?.footprintCode}
											name="footprintCode"
											onChange={collectData}
										/>
									</FormGroup>
									<FormGroup>
										<Label for="footprintCode">Flag Code</Label>
										<Input
											type="text"
											id="flagCode"
											value={editingPlan?.flagCode}
											name="flagCode"
											onChange={collectData}
										/>
									</FormGroup>
								</Form>
							</ModalBody>
							<ModalFooter>
								<Button color="primary" onClick={updatePlan}>Save</Button>{' '}
								<Button color="secondary" onClick={() => setEditingPlan(null)}>Cancel</Button>
							</ModalFooter>
						</Modal>
					</Container>
			}
		</>
	);
};
