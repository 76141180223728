import { useState } from 'react';
import { NavLink as NavLinkRRD, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
	Collapse,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	DropdownToggle,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Media,
	NavbarBrand,
	Navbar,
	NavItem,
	NavLink,
	Nav,
	Container,
	Row,
	Col,
} from 'reactstrap';
import { useStateContext } from 'contexts/contextProvider';

const Sidebar = (props: any) => {
	const isAffiliateUserLoggedIn = localStorage.getItem('isAffiliateUserLoggedIn');
	const { user } = useStateContext();
	const [collapseOpen, setCollapseOpen] = useState(false);
	// toggles collapse between opened and closed (true/false)
	const toggleCollapse = () => {
		setCollapseOpen((data) => !data);
	};
	// closes the collapse
	const closeCollapse = () => {
		setCollapseOpen(false);
	};
	const skippedPaths = ["/login-affiliate", "/login", "/password/reset", '/plans', '/insert-esim', '/unused-esims', '/affiliate'];
	const clientId = user?.clientId || ""
	if (clientId === process.env.REACT_APP_ARAMCO_CLIENT_ID) {
		skippedPaths.push("/index")
	}
	// creates the links that appear in the left menu / Sidebar
	const createLinks = (routes: any[], isAffiliateUserLoggedIn: any) => {
		return routes.map(
			(prop, key) => {
				if (isAffiliateUserLoggedIn) {
					return prop.path === '/index' ? (
						<NavItem key={key}>
							<NavLink
								to={prop.layout + prop.path}
								tag={NavLinkRRD}
								onClick={closeCollapse}
								activeClassName='active'
							>
								<i className={prop.icon} />
								{prop.name}
							</NavLink>
						</NavItem>
					) : null;
				}
				if (user.isAdmin) {
					return prop.path === '/index' || prop.path === '/reports' || prop.path === '/plans' || prop.path === '/insert-esim' || prop.path === '/unused-esims' || prop.path === '/affiliate' ? (
						<NavItem key={key}>
							<NavLink
								to={prop.layout + prop.path}
								tag={NavLinkRRD}
								onClick={closeCollapse}
								activeClassName='active'
							>
								<i className={prop.icon} />
								{prop.name}
							</NavLink>
						</NavItem>
					) : null;
				}
				return (
					!skippedPaths.includes(prop.path) ? (
						<NavItem key={key}>
							<NavLink
								to={prop.layout + prop.path}
								tag={NavLinkRRD}
								onClick={closeCollapse}
								activeClassName='active'
							>
								<i className={prop.icon} />
								{prop.name}
							</NavLink>
						</NavItem>
					) : null
				);
			}
		);
	};

	const { routes, logo } = props;
	let navbarBrandProps;
	if (logo && logo.innerLink) {
		navbarBrandProps = {
			to: logo.innerLink,
			tag: Link,
		};
	} else if (logo && logo.outterLink) {
		navbarBrandProps = {
			href: logo.outterLink,
			target: '_blank',
		};
	}

	return (
		<Navbar
			className='navbar-vertical fixed-left navbar-light bg-white'
			expand='md'
			id='sidenav-main'
		>
			<Container fluid>
				{/* Toggler */}
				<button
					className='navbar-toggler'
					type='button'
					onClick={toggleCollapse}
				>
					<span className='navbar-toggler-icon' />
				</button>
				{/* Brand */}
				{logo ? (
					<NavbarBrand className='pt-0' {...navbarBrandProps}>
						<img
							alt={logo.imgAlt}
							className='navbar-brand-img'
							src={logo.imgSrc}
						/>
					</NavbarBrand>
				) : null}
				{/* User */}
				<Nav className='align-items-center d-md-none'>
					<UncontrolledDropdown nav>
						<DropdownToggle nav className='nav-link-icon'>
							<i className='ni ni-bell-55' />
						</DropdownToggle>
						<DropdownMenu
							aria-labelledby='navbar-default_dropdown_1'
							className='dropdown-menu-arrow'
							right
						>
							<DropdownItem>Action</DropdownItem>
							<DropdownItem>Another action</DropdownItem>
							<DropdownItem divider />
							<DropdownItem>Something else here</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>
					<UncontrolledDropdown nav>
						<DropdownToggle nav>
							<Media className='align-items-center'>
								<span className='avatar avatar-sm rounded-circle'>
									<img
										alt='...'
										src={require('../../assets/img/theme/team-4-800x800.jpg')}
									/>
								</span>
							</Media>
						</DropdownToggle>
						<DropdownMenu className='dropdown-menu-arrow' right>
							<DropdownItem className='noti-title' header tag='div'>
								<h6 className='text-overflow m-0'>Welcome!</h6>
							</DropdownItem>
							<DropdownItem to='/admin/user-profile' tag={Link}>
								<i className='ni ni-single-02' />
								<span>My profile</span>
							</DropdownItem>
							<DropdownItem to='/admin/user-profile' tag={Link}>
								<i className='ni ni-settings-gear-65' />
								<span>Settings</span>
							</DropdownItem>
							<DropdownItem to='/admin/user-profile' tag={Link}>
								<i className='ni ni-calendar-grid-58' />
								<span>Activity</span>
							</DropdownItem>
							<DropdownItem to='/admin/user-profile' tag={Link}>
								<i className='ni ni-support-16' />
								<span>Support</span>
							</DropdownItem>
							<DropdownItem divider />
							<DropdownItem href='#pablo' onClick={() => props.logOut()}>
								<i className='ni ni-user-run' />
								<span>Logout</span>
							</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>
				</Nav>
				{/* Collapse */}
				<Collapse navbar isOpen={collapseOpen}>
					{/* Collapse header */}
					<div className='navbar-collapse-header d-md-none'>
						<Row>
							{logo ? (
								<Col className='collapse-brand' xs='6'>
									{logo.innerLink ? (
										<Link to={logo.innerLink}>
											<img alt={logo.imgAlt} src={logo.imgSrc} />
										</Link>
									) : (
										<a href={logo.outterLink}>
											<img alt={logo.imgAlt} src={logo.imgSrc} />
										</a>
									)}
								</Col>
							) : null}
							<Col className='collapse-close' xs='6'>
								<button
									className='navbar-toggler'
									type='button'
									onClick={toggleCollapse}
								>
									<span />
									<span />
								</button>
							</Col>
						</Row>
					</div>
					{/* Form */}
					<Form className='mt-4 mb-3 d-md-none'>
						<InputGroup className='input-group-rounded input-group-merge'>
							<Input
								aria-label='Search'
								className='form-control-rounded form-control-prepended'
								placeholder='Search'
								type='search'
							/>
							<InputGroupAddon addonType='prepend'>
								<InputGroupText>
									<span className='fa fa-search' />
								</InputGroupText>
							</InputGroupAddon>
						</InputGroup>
					</Form>
					{/* Navigation */}
					<Nav navbar>{createLinks(routes, isAffiliateUserLoggedIn)}</Nav>
					{/* Divider */}
					<hr className='my-3' />
					{/* Heading */}
					<Nav className='mb-md-3' navbar>
						<NavItem className='active-pro active'>
							<NavLink onClick={() => props.logOut()}>
								<i className='ni ni-user-run' />
								Logout
							</NavLink>
						</NavItem>
					</Nav>
				</Collapse>
			</Container>
		</Navbar>
	);
};

Sidebar.defaultProps = {
	routes: [{}],
};

Sidebar.propTypes = {
	// links that will be displayed inside the component
	routes: PropTypes.arrayOf(PropTypes.object),
	logo: PropTypes.shape({
		// innerLink is for links that will direct the user within the app
		// it will be rendered as <Link to="...">...</Link> tag
		innerLink: PropTypes.string,
		// outterLink is for links that will direct the user outside the app
		// it will be rendered as simple <a href="...">...</a> tag
		outterLink: PropTypes.string,
		// the image src of the logo
		imgSrc: PropTypes.string.isRequired,
		// the alt for the img
		imgAlt: PropTypes.string.isRequired,
	}),
};

export default Sidebar;
