import React from "react";
import { IReport } from "types/interfaces";
import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";


type ReportDetailsProps = {
    report: IReport;
    setSelectedReport: any
};

export const ReportDetails: React.FC<ReportDetailsProps> = ({ report, setSelectedReport }) => {
    if (!report) {
        return <div>Report not found.</div>;
    }

    return <Container className="mt-4">
        <h2 className="mb-4">Report details</h2>
        <Row>
            <Col md={12}>
                <button className="btn btn-success mb-2" onClick={() => setSelectedReport(undefined)}>Back</button>
                <Card>
                    <ListGroup variant="flush">
                        <ListGroup.Item>
                            <strong>Client Name:</strong> {report.clientName}
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>Client Email:</strong> {report.clientEmail}
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>Issue Description:</strong> {report.issueDescription}
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>Issue Priority:</strong> {report.issuePriority}
                        </ListGroup.Item>
                        {report.eSimIccid && (
                            <ListGroup.Item>
                                <strong>eSIM ICCID:</strong> {report.eSimIccid}
                            </ListGroup.Item>
                        )}
                        {report.apiCall && (
                            <ListGroup.Item>
                                <strong>API Call:</strong> {report.apiCall}
                            </ListGroup.Item>
                        )}
                        {report.apiCallTimestamp && (
                            <ListGroup.Item>
                                <strong>API Call Timestamp:</strong> {report.apiCallTimestamp}
                            </ListGroup.Item>
                        )}
                        {report.apiResponse && (
                            <ListGroup.Item>
                                <strong>API Response:</strong> {report.apiResponse}
                            </ListGroup.Item>
                        )}
                        {report.endUserLocation && (
                            <ListGroup.Item>
                                <strong>End User Location:</strong> {report.endUserLocation}
                            </ListGroup.Item>
                        )}
                        <ListGroup.Item>
                            <strong>Issue Type:</strong> {report.issueType}
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>Status:</strong> {report.isOpen ? "Open" : "Closed"}
                        </ListGroup.Item>
                        {report.images && (
                            <>
                                <ListGroup.Item>
                                    <strong>Images:</strong>
                                </ListGroup.Item>
                                {report.images.map(image => <ListGroup.Item>
                                    <img src={image} alt="" />
                                </ListGroup.Item>
                                )}
                            </>
                        )}
                    </ListGroup>
                </Card>
            </Col>
        </Row>
    </Container>
};
