import api from '../config/betaApiConfig';

const getAffiliationDetails = () => {
    const route = `/affiliation/details`;
    return api.get(route);
};

const getAgentDetails = () => {
    const route = `/affiliation/agent`;
    return api.get(route);
};

const getAffiliateMarketerProfile = () => {
    const route = `/users/profile`;
    return api.get(route);
};

const getAffiliationStatistics = () => {
    const route = `/affiliation/statistics`;
    const config = {
        headers: {
            'api-key': process.env.REACT_APP_B2B_API_KEY as string,
            'client-id': process.env.REACT_APP_B2B_API_CLIENT_ID as string
        }
    };
    return api.get(route, config);
};

const getCouponProviderBilling = (body: object) => {
    const route = `/coupon-provider/billing`;
    const config = {
        headers: {
            'api-key': process.env.REACT_APP_B2B_API_KEY as string,
            'client-id': process.env.REACT_APP_B2B_API_CLIENT_ID as string
        }
    };
    console.log("body: ", body);

    return api.post(route, body, config);
};

export const betaApi = {
    getAffiliationDetails,
    getAffiliateMarketerProfile,
    getAffiliationStatistics,
    getCouponProviderBilling,
    getAgentDetails,
};