import { useState, useEffect, useRef } from 'react';
import {
	Container,
	UncontrolledAlert,
	Button,
	Form,
	FormGroup,
	Input,
	Label,
} from 'reactstrap';
import Header from 'components/Headers/Header';
import { apiEndpoints } from '../../services/api/endpoints/apiEndpoints';
import { EsimProvider } from 'types/enums';
import { useStateContext } from 'contexts/contextProvider';


interface Props {
	state: object;
}

interface IEsim {
	provider: string,
	iccid: string,
	matchingId: string,
	smdpPlus: string,
	msisdn: string,
	pukCode: string,
	planProductCode: string,
}

interface FormData {
	esim: IEsim,
	isSingleEsim: true,
	files?: any[];
}

export const InsertEsim = (props: Props) => {

	const [formData, setFormData] = useState<FormData>(
		{
			esim: {
				provider: "",
				iccid: "",
				matchingId: "",
				smdpPlus: "",
				msisdn: "",
				pukCode: "",
				planProductCode: "",
			},
			isSingleEsim: true,
			files: [] as any,
		}
	);

	const [errorMsg, setErrorMsg] = useState<string>();
	const { setUser } = useStateContext();

	function collectData(e: any) {
		setFormData({
			...formData,
			esim: {
				...formData.esim,
				[e.target.name.trim()]: e.target.value.trim(),
			}
		});
	};

	const getUser = async () => {
		try {
			const response = await apiEndpoints.getAdmin()
			setUser({ ...response.data.admin, isAdmin: true });
		} catch (error) { }
	};

	function alertError() {
		if (errorMsg) {
			return (
				<UncontrolledAlert className='alert-default mt-3' fade={false}>
					<span className='alert-inner--text'>{errorMsg}</span>
				</UncontrolledAlert>
			);
		}
	};

	const handleAttachmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = e.target;
		if (files) {
			setFormData(
				(prevData: FormData) => (
					{
						...prevData,
						files: Array.from(files),
					}
				)
			);
		}
	};


	async function handleSubmitForm(e: React.FormEvent<HTMLFormElement>) {
		try {
			const postFormData = new FormData();
			for (const [key, value] of Object.entries(formData)) {
				if (key === "files") {
					for (const file of value) {
						postFormData.append("files", file);
					}
				} else if (key === "esim") {
					for (const [esimKey, esimValue] of Object.entries(value)) {
						if (esimKey === "smdpPlus" && value.provider === EsimProvider.WTR) {
							const smdpAddress = esimValue as string;
							postFormData.append(`esim[${esimKey}]`, smdpAddress);
							continue;
						}
						postFormData.append(`esim[${esimKey}]`, esimValue as string);
					}
				} else if (key === "isSingleEsim") {
					postFormData.append(key, String(value)); // Ensure the value is a string, FormData doesn't support booleans
				}
			}
			e.preventDefault();
			if (formData.esim.provider === EsimProvider.ORANGE) {
				await apiEndpoints.insertOrangeEsim(postFormData);
			} else {
				await apiEndpoints.insertWtrEsim(postFormData);
			}
			setErrorMsg('Esim successfully inserted');
			window.location.reload();
		} catch (error) {
			setErrorMsg('Something went wrong please try again later');
			console.log("Error at insertEsim() ", error);
		}
	};



	useEffect(
		() => {
			getUser()
		},
		[getUser]
	)

	return (
		<>
			<Header state={props.state} />
			<Container fluid>
				<Form onSubmit={handleSubmitForm}>
					<FormGroup>
						<Label for="provider">Provider (*)</Label>
						<Input
							type="select"
							id="provider"
							name="provider"
							onChange={collectData}
							required
						>
							<option value="">Select Provider</option>
							<option value={EsimProvider.ORANGE}>{EsimProvider.ORANGE}</option>
							<option value={EsimProvider.WTR}>{EsimProvider.WTR}</option>
						</Input>
					</FormGroup>
					{formData?.esim?.provider !== EsimProvider.ORANGE && <FormGroup>
						<Label for="name">ICCID (*)</Label>
						<Input
							type="text"
							id="iccid"
							name="iccid"
							onChange={collectData}
						/>
					</FormGroup>}
					<FormGroup>
						<Label for="productCode">Matching Id (*)</Label>
						<Input
							type="text"
							id="matchingId"
							name="matchingId"
							onChange={collectData}
							required
						/>
					</FormGroup>
					<FormGroup>
						<Label for="data">Smdp Plus (*)</Label>
						<Input
							type="text"
							id="smdpPlus"
							name="smdpPlus"
							onChange={collectData}
							required
						/>
					</FormGroup>
					<FormGroup>
						<Label for="duration">Msisdn (*)</Label>
						<Input
							type="text"
							id="msisdn"
							name="msisdn"
							onChange={collectData}
							required
						/>
					</FormGroup>
					{formData?.esim?.provider === EsimProvider.ORANGE && <FormGroup>
						<Label for="quantity">Puk Code</Label>
						<Input
							type="text"
							id="pukCode"
							name="pukCode"
							onChange={collectData}
						/>
					</FormGroup>}
					{formData?.esim?.provider !== EsimProvider.WTR && <FormGroup>
						<Label for="priceGbp">Plan Product Code (*)</Label>
						<Input
							type="text"
							id="planProductCode"
							name="planProductCode"
							onChange={collectData}
						/>
					</FormGroup>}


					<div className="form-group mt-2">
						<label htmlFor="files">QrCode Upload (*)</label>
						<div className="input-group mb-3">
							<div className="custom-file">
								<input
									type="file"
									className="custom-file-input"
									id="files"
									aria-describedby="files-help"
									multiple
									onChange={handleAttachmentChange}
									required
								/>
								<label className="custom-file-label" htmlFor="files">
									{
										formData?.files?.length === 0 ?
											"Choose file" :
											formData?.files?.[0].name
									}
								</label>
							</div>
						</div>
					</div>
					<Button type="submit" color="primary" >Save</Button>
				</Form>

			</Container>
			{alertError()}

		</>
	);
};
