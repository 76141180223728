import React, { useRef, useReducer, useState } from 'react';
import { useLocation, Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import AdminFooter from 'components/Footers/AdminFooter';
import Sidebar from 'components/Sidebar/Sidebar';
import { useStateContext } from '../contexts/contextProvider';
import { apiEndpoints } from '../services/api/endpoints/apiEndpoints';
import { initialState, ActionsTypes, Reducer } from '../store/reducers';
import routes from 'routes';
import { eSimStatus } from 'types/enums';

type RoutesType = {
	path: string;
	name: string;
	icon: string;
	component: (props: any) => JSX.Element;
	layout: string;
}

const getRoutes = (
	routes: RoutesType[],
	isUserLoggedIn: string | null,
	isAffiliateUserLoggedIn: string | null,
	state: any,
	getOrdersAndEsims: any,
	getSalesValue: any,
	clientId: string,
	activeEsims: any = null,
	pendingEsims: any = null,
	areEsimsFetched: boolean = false
) => {

	const isAramco = process.env.REACT_APP_ARAMCO_CLIENT_ID === clientId;
	return routes.map((prop, key) => {
		if (prop.layout === '/admin') {
			return (
				<Route path={prop.layout + prop.path} key={key}>
					{isUserLoggedIn || isAffiliateUserLoggedIn ? (
						prop.path === "/index" && isAramco ?
							<Redirect to='/admin/subscriptions' /> :
							<prop.component state={{ ...state, getOrdersAndEsims, getSalesValue, isAramco, activeEsims, pendingEsims, areEsimsFetched }} />
					) : (
						<Redirect to='/auth/login' />
					)}
				</Route>
			);
		} else {
			return null;
		}
	});
};

const getBrandText = (props: any) => {
	for (let i = 0; i < routes.length; i++) {
		if (
			props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
			-1
		) {
			return routes[i].name;
		}
	}
	return 'Brand';
};

const now = new Date();
const previousMonthEndDate = new Date(now.getFullYear(), now.getMonth(), 0); // Last day of the previous month
const previousMonthStartDate = new Date(now.getFullYear(), now.getMonth() - 1, 1); // First day of the previous month
const monthBeforeStartDate = new Date(now.getFullYear(), now.getMonth() - 2, 1); // First day of the month before the previous month


const Admin = (props: any) => {
	const [activeEsims, setActiveEsims] = useState([]);
	const [pendingEsims, setPendingEsims] = useState([]);
	const [areEsimsFetched, setAreEsimsFetched] = useState(false);
	const { setOrders, orders, setESimsIccids, user, setUser } = useStateContext();
	const mainContent = useRef<HTMLDivElement>({} as HTMLDivElement);
	const [state, dispatch] = useReducer(Reducer, initialState);
	const location = useLocation();
	const esimsPerPage = useRef(50000000);
	const isUserLoggedIn = localStorage.getItem('isUserLoggedIn');
	const isAffiliateUserLoggedIn = localStorage.getItem('isAffiliateUserLoggedIn');
	const history = useHistory();

	const getOrdersAndEsims = React.useCallback(
		async (clientId: string = "") => {
			try {
				const fetchingProps = clientId !== "" ? { clientId, pageSize: 10000 } : { pageSize: 10000 };
				const response = await apiEndpoints.fetchOrders(fetchingProps);
				const beforeLastMonthOrders = [];
				const lastMonthOrders = [];
				const currentMonthOrders = [];
				const beforeLastMonthEsims = [];
				const lastMonthEsims = [];
				const currentMonthEsims = [];

				response.data.orders.forEach((order: any) => {
					const orderDate = order.createdAt;
					switch (true) {
						case orderDate > previousMonthEndDate.toISOString():
							currentMonthOrders.push(order);
							currentMonthEsims.push(order.output.iccid);
							break;

						case orderDate < previousMonthStartDate.toISOString() &&
							orderDate > monthBeforeStartDate.toISOString():
							beforeLastMonthOrders.push(order);
							beforeLastMonthEsims.push(order.output.iccid);
							break;

						case orderDate > previousMonthStartDate.toISOString() &&
							orderDate < previousMonthEndDate.toISOString():
							lastMonthOrders.push(order);
							lastMonthEsims.push(order.output.iccid);
							break;

						default:
							break;
					}
				});

				const payload = {
					beforeLastMonthOrdersCount: beforeLastMonthOrders.length,
					lastMonthOrdersCount: lastMonthOrders.length,
					currentMonthOrdersCount: currentMonthOrders.length,
					currentMonthEsimsCount: currentMonthEsims.length,
					lastMonthEsimsCount: lastMonthEsims.length,
				};

				setOrders(response.data.orders);
				dispatch(
					{
						type: ActionsTypes.UPDATE_ORDER_ESIMS,
						payload
					}
				);
				const iccids = orders.map((order) => {
					return { iccid: order.output.iccid, created_date: order.createdAt };
				});
				setESimsIccids(iccids);
				return payload
			} catch (error) {
			}
		}, [setESimsIccids, setOrders]
	);

	const getSalesValue = React.useCallback(
		async (clientId: string = "") => {
			try {
				const fetchingProps = clientId !== "" ? { clientId } : {};
				const response = await apiEndpoints.getSalesValue(fetchingProps);
				const payload = {
					beforeLastMonthSales: response.data.sales.beforeLastMonthSales,
					lastMonthSales: response.data.sales.lastMonthSales,
					currentMonthSales: response.data.sales.currentMonthSales,
				}
				dispatch(
					{
						type: ActionsTypes.UPDATE_SALES,
						payload
					}
				);
				return payload;
			} catch (error) { }
		},
		[]
	);

	async function getEsims() {
		try {
			const params = {
				pageSize: esimsPerPage.current,
				page: 1
			};
			const response = await apiEndpoints.fetchClientEsims(params);
			setActiveEsims(response.data.data.filter((item: any) => item.status === eSimStatus.Installed));
			setPendingEsims(response.data.data.filter((item: any) => item.status === eSimStatus.Released));
			setAreEsimsFetched(true);
		} catch (error: any) {
			console.log("Error at getEsims");
		}
	}

	const logOut = () => {
		localStorage.clear();
		history.push('/auth/login');
	}

	const getUser = async () => {
		try {
			const response = await Promise.any(
				[
					apiEndpoints.getAdmin(),
					apiEndpoints.getClient(),
				]
			)
			setUser(response.data.client ? response.data.client : { ...response.data.admin, isAdmin: true });
			return response.data.client || response.data.admin;
		} catch (error) { }
	}

	const initApp = React.useCallback(async () => {
		try {
			const [user] = await Promise.all([getUser(), getOrdersAndEsims(), getSalesValue()]);

			if (user && user?.clientId === process.env.REACT_APP_ARAMCO_CLIENT_ID) {
				getEsims();
			}
		} catch (error) {
			console.error(error);
		}
	}, [getOrdersAndEsims, getSalesValue]);


	React.useEffect(() => {
		initApp();
		document.documentElement.scrollTop = 0;
		if (document.scrollingElement) document.scrollingElement.scrollTop = 0;
		mainContent.current.scrollTop = 0;
	}, [initApp, location]);
	const clientId = user?.clientId || "";
	return (
		<>
			<Sidebar
				{...props}
				logOut={logOut}
				routes={routes}
				logo={{
					innerLink: '/admin/index',
					imgSrc: require('../assets/img/brand/Logotype_purple.png'),
					imgAlt: '...',
				}}
			/>
			<div className='main-content' ref={mainContent}>
				<AdminNavbar
					{...props}
					userName={user?.name}
					logOut={logOut}
					brandText={getBrandText(props)}
				/>
				<Switch>
					{
						getRoutes(
							routes,
							isUserLoggedIn,
							isAffiliateUserLoggedIn,
							state,
							getOrdersAndEsims,
							getSalesValue,
							clientId,
							activeEsims,
							pendingEsims,
							areEsimsFetched
						)
					}
					<Redirect from='*' to='/admin/index' />
				</Switch>
				<Container fluid>
					<AdminFooter />
				</Container>
			</div>
		</>
	);
};

export default Admin;
