import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

interface APIIssueFormProps {
    handleSubmit: (e: any) => void;
    handleInputChange: (e: any) => void;
    handleSubmitForm: (e: any) => void;
    handleAttachmentChange: (e: any) => void;
}

export const ApiIssueForm: React.FC<APIIssueFormProps> = (
    {
        handleSubmit,
        handleInputChange,
        handleAttachmentChange,
        handleSubmitForm,
    }
) => {
    return <Form onSubmit={handleSubmitForm} className="mt-4">
        <Form.Group as={Row} controlId="subject">
            <Form.Label column sm={3}>
                Subject:
            </Form.Label>
            <Col sm={12}>
                <Form.Control
                    type="text"
                    placeholder="Enter subject"
                    name="subject"
                    onChange={handleInputChange}
                    required
                />
            </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="apiCall">
            <Form.Label column sm={3}>
                API Call:
            </Form.Label>
            <Col sm={12}>
                <Form.Control
                    type="text"
                    placeholder="Please copy / paste here, the API call being queried or failing"
                    name="apiCall"
                    onChange={handleInputChange}
                    required
                />
            </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="timestamp">
            <Form.Label column sm={3}>
                Timestamp / Date:
            </Form.Label>
            <Col sm={12}>
                <Form.Control
                    type="text"
                    placeholder="Please enter the Timestamp / Date, of the API call"
                    name="timestamp"
                    onChange={handleInputChange}
                    required
                />
            </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="apiResponse">
            <Form.Label column sm={3}>
                API Response:
            </Form.Label>
            <Col sm={12}>
                <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Please copy / paste here the response from the API call in question"
                    name="apiResponse"
                    onChange={handleInputChange}
                    required
                />
            </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="issueDescription">
            <Form.Label column sm={3}>
                Description:
            </Form.Label>
            <Col sm={12}>
                <Form.Control
                    as="textarea"
                    rows={7}
                    placeholder="Please enter the further details of your request. A member of our support staff will respond as soon as possible."
                    name="issueDescription"
                    onChange={handleInputChange}
                    required
                />
            </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="esimIccid">
            <Form.Label column sm={3}>
                eSIM ICCID(optional):
            </Form.Label>
            <Col sm={12}>
                <Form.Control
                    type="text"
                    placeholder="eSIM ICCID/Serial Number (begins with 89...)"
                    name="timestamp"
                    onChange={handleInputChange}
                />
            </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="issuePriority">
            <Form.Label column sm={3}>
                Priority:
            </Form.Label>
            <Col sm={12}>
                <select
                    className="form-control"
                    id="issuePriority"
                    name="issuePriority"
                    onChange={handleInputChange}
                >
                    <option value="low"> - </option>
                    <option value="low">Low</option>
                    <option value="normal">Normal</option>
                    <option value="high">High</option>
                    <option value="urgent">Urgent</option>
                </select>
            </Col>
        </Form.Group>

        <div className="form-group mt-2">
            <label htmlFor="files">files (optional)</label>
            <div className="input-group mb-3">
                <div className="custom-file">
                    <input
                        type="file"
                        className="custom-file-input"
                        id="files"
                        aria-describedby="files-help"
                        multiple
                        onChange={handleAttachmentChange}
                    />
                    <label className="custom-file-label" htmlFor="files">
                        Choose file
                    </label>
                </div>
            </div>
            <small id="attachments-help" className="form-text text-muted">
                You can attach up to 5 files. Max size per file is 10MB.
            </small>
        </div>
        <button type="submit" className="btn btn-primary">
            Submit
        </button>
    </Form>
};
