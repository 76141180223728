import betaAuthApiConfig from '../config/betaAuthConfig';

const login = (email: string, password: string) => {
    const route = 'login/affiliate';
    const body = { email, password };
    return betaAuthApiConfig.post(route, body);
};

export const authApi = {
    login,
};
