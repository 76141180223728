type State = {
    beforeLastMonthOrdersCount: number;
    lastMonthOrdersCount: number;
    currentMonthOrdersCount: number;
    beforeLastMonthSales: number;
    lastMonthSales: number;
    currentMonthSales: number;
    currentMonthEsimsCount: number;
    lastMonthEsimsCount: number;
};

export enum ActionsTypes {
    UPDATE_ORDERS = "UPDATE_ORDERS",
    UPDATE_SALES = "UPDATE_SALES",
    UPDATE_ESIMS = "UPDATE_ESIMS",
    UPDATE_ORDER_ESIMS = "UPDATE_ORDER_ESIMS",
};

export const initialState: State = {
    beforeLastMonthOrdersCount: 0,
    lastMonthOrdersCount: 0,
    currentMonthOrdersCount: 0,
    beforeLastMonthSales: 0,
    lastMonthSales: 0,
    currentMonthSales: 0,
    currentMonthEsimsCount: 0,
    lastMonthEsimsCount: 0,
};

type Action = {
    type: ActionsTypes,
    payload: Partial<State>
}

export const Reducer = (
    state: State = initialState,
    action: Action
): State => {
    switch (action.type) {
        case ActionsTypes.UPDATE_ORDERS:
            return {
                ...state,
                beforeLastMonthOrdersCount: action.payload.beforeLastMonthOrdersCount || state.beforeLastMonthOrdersCount,
                lastMonthOrdersCount: action.payload.lastMonthOrdersCount || state.lastMonthOrdersCount,
                currentMonthOrdersCount: action.payload.currentMonthOrdersCount || state.currentMonthOrdersCount,
            };
        case ActionsTypes.UPDATE_SALES:
            return {
                ...state,
                beforeLastMonthSales: action.payload.beforeLastMonthSales || state.beforeLastMonthSales,
                lastMonthSales: action.payload.lastMonthSales || state.lastMonthSales,
                currentMonthSales: action.payload.currentMonthSales || state.currentMonthSales,
            };
        case ActionsTypes.UPDATE_ESIMS:
            return {
                ...state,
                currentMonthEsimsCount: action.payload.currentMonthEsimsCount || state.currentMonthEsimsCount,
                lastMonthEsimsCount: action.payload.lastMonthEsimsCount || state.lastMonthEsimsCount,
            };
        case ActionsTypes.UPDATE_ORDER_ESIMS:
            return {
                ...state,
                beforeLastMonthOrdersCount: action.payload.beforeLastMonthOrdersCount || state.beforeLastMonthOrdersCount,
                lastMonthOrdersCount: action.payload.lastMonthOrdersCount || state.lastMonthOrdersCount,
                currentMonthOrdersCount: action.payload.currentMonthOrdersCount || state.currentMonthOrdersCount,
                currentMonthEsimsCount: action.payload.currentMonthEsimsCount || state.currentMonthEsimsCount,
                lastMonthEsimsCount: action.payload.lastMonthEsimsCount || state.lastMonthEsimsCount,
            };
        default:
            return state;
    }
};
