import { EndUserIssueEscalationForm } from "./EndUserIssueEscalationForm";
import { ApiIssueForm } from "./APIIssueForm";
import { GeneralEnquiryForm } from "./GeneralEnquiryForm";
import { apiEndpoints } from "../../services/api/endpoints/apiEndpoints";
import { useEffect, useState } from "react";

export enum HelpOptions {
    EndUserIssueEscalation = "End user issue escalation",
    APIIssue = "API issue",
    GeneralEnquiry = "General enquiry",
    default = "-",
}

interface Props {
    value: HelpOptions;
    checkboxQuestions: CheckboxQuestion[];
    setErrorMsg: any;
}

interface CheckboxQuestion {
    title: string;
    label: string;
}

interface FormData {
    subject: string;
    issuePriority: string;
    issueDescription: string;
    apiCall?: string;
    timestamp?: string;
    apiResponse?: string;
    esimIccid?: string;
    issueType?: string;
    files?: any[];
}

export const RenderFormBasedOnValue = (
    {
        value,
        checkboxQuestions,
        setErrorMsg,
    }: Props
): JSX.Element => {
    const [formData, setFormData] = useState<FormData>(
        {
            subject: "",
            issuePriority: "",
            issueDescription: "",
            apiCall: "",
            timestamp: "",
            apiResponse: "",
            esimIccid: "",
            issueType: "",
            files: [] as any,
        }
    );

    const handleInputChange = (
        e: React.ChangeEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
    ) => {
        const { name, value } = e.target;
        setFormData(
            (prevData: FormData) => (
                {
                    ...prevData,
                    [name]: value,
                }
            )
        );
    };

    const handleAttachmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;
        if (files) {
            setFormData(
                (prevData: FormData) => (
                    {
                        ...prevData,
                        files: Array.from(files),
                    }
                )
            );
        }
    };

    const handleLocationChange = (value: string) => {
        setFormData(
            (prevData: FormData) => (
                {
                    ...prevData,
                    userLocation: value,
                }
            )
        );
    };

    const handleSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            const postFormData = new FormData();
            for (const [key, value] of Object.entries(formData)) {
                if (key === "files") {
                    for (const file of value) {
                        postFormData.append("files", file);
                    }
                    continue;
                }
                postFormData.append(key, value);
            }
            e.preventDefault();
            setErrorMsg("");
            const response = await apiEndpoints.reportIssue(postFormData);
            setErrorMsg(response.data.message);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } catch (error) {
            setErrorMsg("Something went wrong, please try again later");
        }
    };

    useEffect(
        () => {
            setFormData(
                (prevData: FormData) => (
                    {
                        ...prevData,
                        issueType: value,
                    }
                )
            );
        },
        [value]
    );

    switch (value) {
        case HelpOptions.EndUserIssueEscalation:
            return <EndUserIssueEscalationForm
                checkboxQuestions={checkboxQuestions}
                locationLabel="User location"
                handleSubmit={handleSubmitForm}
                handleInputChange={handleInputChange}
                handleLocationChange={handleLocationChange}
            />

        case HelpOptions.APIIssue:
            return <ApiIssueForm
                handleSubmit={handleSubmitForm}
                handleInputChange={handleInputChange}
                handleSubmitForm={handleSubmitForm}
                handleAttachmentChange={handleAttachmentChange}
            />;

        case HelpOptions.GeneralEnquiry:
            return <GeneralEnquiryForm
                handleSubmit={handleSubmitForm}
                handleInputChange={handleInputChange}
                handleAttachmentChange={handleAttachmentChange}
            />;

        default:
            return <></>;
    }
};
