import { useStateContext } from "../../contexts/contextProvider";
import { useState } from "react";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

function calculateMonthlyGrowthRate(currentMonth: number, lastMonth: number) {
  const divideBy = lastMonth > 0 ? lastMonth : 1;
  return ((currentMonth - lastMonth) / divideBy) * 100;
}

function calculatePerformance(beforeLastMonthSales: number, lastMonthSales: number, currentMonthSales: number): number {
  const performance = ((beforeLastMonthSales + lastMonthSales + currentMonthSales) / (3 * lastMonthSales > 0 ? lastMonthSales : 1)) * 100;
  return performance;
}


const Header = (props: any) => {
  const { user } = useStateContext();
  const [isLoading, setIsloading] = useState(true);

  const isAffiliateUserLoggedIn = localStorage.getItem('isAffiliateUserLoggedIn');
  const isAgentLoggedIn = localStorage.getItem('isAgentLoggedIn');

  const ordersGrowth = Math.round(calculateMonthlyGrowthRate(
    props.state.currentMonthOrdersCount,
    props.state.lastMonthOrdersCount
  ));
  const eSimsGrowth = Math.round(calculateMonthlyGrowthRate(
    props.state.currentMonthEsimsCount,
    props.state.lastMonthEsimsCount,
  ));
  const SalesGrowth = Math.round(calculateMonthlyGrowthRate(
    props.state.currentMonthSales,
    props.state.lastMonthSales,
  ));
  const performance = Math.round(calculatePerformance(
    props.state.beforeLastMonthSales,
    props.state.lastMonthSales,
    props.state.currentMonthSales,
  ));
  setTimeout(() => setIsloading(false), 1000);

  return (
    <>
      <div className="header bg-gradient-purple pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            {
              !isLoading ?
                !props.state.isAramco ?
                  <Row>
                    <Col lg="6" xl={isAffiliateUserLoggedIn ? '4' : '3'}>
                      <Card className="card-stats mb-4 mb-xl-0">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                {isAffiliateUserLoggedIn ? isAgentLoggedIn ? 'Users' : 'Promotions' : 'Orders'}
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {isAffiliateUserLoggedIn ? isAgentLoggedIn ? props.state.currentMonthUsers : props.state.promotionsLength : props.state.currentMonthOrdersCount}
                              </span>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                <i className="fas fa-chart-bar" />
                              </div>
                            </Col>
                          </Row>
                          <p className="mt-3 mb-0 text-muted text-sm">
                            <span
                              className={
                                ordersGrowth >= 0
                                  ? "text-success mr-2"
                                  : "text-danger mr-2"
                              }
                            >
                              <i
                                className={
                                  ordersGrowth >= 0
                                    ? "fa fa-arrow-up"
                                    : "fas fa-arrow-down"
                                }
                              />{" "}
                              {ordersGrowth}%
                            </span>{" "}
                            <span className="text-nowrap">Since last month </span>
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="6" xl={isAffiliateUserLoggedIn ? '4' : '3'}>
                      <Card className="card-stats mb-4 mb-xl-0">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >

                                {isAffiliateUserLoggedIn ? isAgentLoggedIn ? 'Orders' : 'Usages' : 'Esims'}

                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {isAffiliateUserLoggedIn ? isAgentLoggedIn ? props.state.currentMonthPaymentsCount : props.state.currentMonthPromotionUsage.length : props.state.currentMonthEsimsCount}
                              </span>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                                <i className="fas fa-chart-pie" />
                              </div>
                            </Col>
                          </Row>
                          <p className="mt-3 mb-0 text-muted text-sm">
                            <span
                              className={
                                eSimsGrowth >= 0
                                  ? "text-success mr-2"
                                  : "text-danger mr-2"
                              }
                            >
                              <i
                                className={
                                  eSimsGrowth >= 0
                                    ? "fa fa-arrow-up"
                                    : "fas fa-arrow-down"
                                }
                              />{" "}
                              {eSimsGrowth}%
                            </span>{" "}
                            <span className="text-nowrap">Since last month</span>
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="6" xl={isAffiliateUserLoggedIn ? '4' : '3'}>
                      <Card className="card-stats mb-4 mb-xl-0">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                {isAffiliateUserLoggedIn ? 'Revenue' : 'Sales'}
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {isAffiliateUserLoggedIn ? props.state.currentMonthRevenue?.toFixed(2) : props.state.currentMonthSales?.toFixed(2)} {user.billingCurrency?.toUpperCase() || isAgentLoggedIn ? '$' : '£'}
                              </span>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                                <i className="fas fa-users" />
                              </div>
                            </Col>
                          </Row>
                          <p className="mt-3 mb-0 text-muted text-sm">
                            <span
                              className={
                                SalesGrowth >= 0
                                  ? "text-success mr-2"
                                  : "text-danger mr-2"
                              }
                            >
                              <i
                                className={
                                  SalesGrowth >= 0
                                    ? "fa fa-arrow-up"
                                    : "fas fa-arrow-down"
                                }
                              />{" "}
                              {SalesGrowth ? SalesGrowth : 0}%
                            </span>{" "}
                            <span className="text-nowrap">Since last month</span>
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                    {isAffiliateUserLoggedIn ? <></> :
                      <Col lg="6" xl="3">
                        <Card className="card-stats mb-4 mb-xl-0">
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h5"
                                  className="text-uppercase text-muted mb-0"
                                >
                                  Performance
                                </CardTitle>
                                <span className="h2 font-weight-bold mb-0">
                                  {Math.round(performance)}%
                                </span>
                              </div>
                              <Col className="col-auto">
                                <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                                  <i className="fas fa-percent" />
                                </div>
                              </Col>
                            </Row>
                            <p className="mt-3 mb-0 text-muted text-sm">
                              <span className="text-success mr-2"></span>{" "}
                              <span className="text-nowrap"></span>
                            </p>
                          </CardBody>
                        </Card>
                      </Col>
                    }
                  </Row> :
                  <Row>
                    <Col lg="6" xl={isAffiliateUserLoggedIn ? '4' : '3'}>
                      <Card className="card-stats mb-4 mb-xl-0">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Active esims
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {
                                  props.state.areEsimsFetched ? props.state.activeEsims.length : <div className="spinner-border mt-2 text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                }
                              </span>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                <i className="fas fa-chart-bar" />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="6" xl={isAffiliateUserLoggedIn ? '4' : '3'}>
                      <Card className="card-stats mb-4 mb-xl-0">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Pending esims
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {
                                  props.state.areEsimsFetched ? props.state.pendingEsims.length : <div className="spinner-border mt-2 text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                }
                              </span>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                                <i className="fas fa-chart-pie" />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row> :
                <div className="spinner-border mt-2 text-default" role="status" style={{ position: "absolute", left: "45%", top: "100px", width: "3rem", height: "3rem" }} >
                  <span className="sr-only">Loading...</span>
                </div>

            }
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
