import React from 'react';
import { useLocation, Route, Switch, Redirect } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

import AuthNavbar from 'components/Navbars/AuthNavbar';
import AuthFooter from 'components/Footers/AuthFooter';

import routes from 'routes';


type RoutesType = {
	path: string;
	name: string;
	icon: string;
	component: (props: any) => JSX.Element;
	layout: string;
}

const getRoutes = (routes: RoutesType[], isUserLoggedIn: string | null, isAffiliateUserLoggedIn: string | null) => {
	return routes.map((prop, key) => {
		if (prop.layout === '/auth') {
			return (
				<Route path={prop.layout + prop.path} key={key}>
					{!isUserLoggedIn && !isAffiliateUserLoggedIn? (
						<prop.component />
					) : (
						<Redirect to='/admin/index' />
					)}
				</Route>
			);
		} else {
			return null;
		}
	});
};

const Auth = (props: any) => {
	const mainContent = React.useRef<HTMLDivElement>({} as HTMLDivElement);
	const location = useLocation();
	const isUserLoggedIn = localStorage.getItem('isUserLoggedIn');
	const isAffiliateUserLoggedIn = localStorage.getItem('isAffiliateUserLoggedIn');
	React.useEffect(() => {
		document.body.classList.add('bg-default');
		return () => {
			document.body.classList.remove('bg-default');
		};
	}, []);
	React.useEffect(() => {
		document.documentElement.scrollTop = 0;
		if (document.scrollingElement) document.scrollingElement.scrollTop = 0;
		mainContent.current.scrollTop = 0;
	}, [location]);

	return (
		<>
			<div className='main-content' ref={mainContent}>
				<AuthNavbar />
				<div className='header bg-gradient-info py-7 py-lg-8'>
					<Container>
						<div className='header-body text-center mb-7'>
							<Row className='justify-content-center'>
								<Col lg='5' md='6'>
									<h1 className='text-white'>Welcome!</h1>
									<p className='text-lead text-light'>
										You can log in to your account from here!
										please if you are an affiliate marketer switch to the affiliate login from the top nav.
										Thanks
									</p>
								</Col>
							</Row>
						</div>
					</Container>
					<div className='separator separator-bottom separator-skew zindex-100'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							preserveAspectRatio='none'
							version='1.1'
							viewBox='0 0 2560 100'
							x='0'
							y='0'
						>
							<polygon
								className='fill-default'
								points='2560 0 2560 100 0 100'
							/>
						</svg>
					</div>
				</div>
				{/* Page content */}
				<Container className='mt--8 pb-5'>
					<Row className='justify-content-center'>
						<Switch>
							{getRoutes(routes, isUserLoggedIn, isAffiliateUserLoggedIn)}
							<Redirect from='*' to='/auth/login' />
						</Switch>
					</Row>
				</Container>
			</div>
			<AuthFooter />
		</>
	);
};

export default Auth;
