import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    UncontrolledAlert,
} from 'reactstrap';
import { useState } from 'react';
import { apiEndpoints } from 'services/api/endpoints/apiEndpoints';
import { useHistory } from "react-router-dom";

const data = Object.freeze({
    clientId: '',
    clientSecret: '',
    password: '',
    confirmPassword: '',
});

export const ResetPassword = () => {
    const [userLoginInfo, setUserLoginInfo] = useState(data);
    const [errorMsg, setErrorMsg] = useState("");
    const history = useHistory();

    function collectData(e: any) {
        setUserLoginInfo({
            ...userLoginInfo,
            [e.target.name.trim()]: e.target.value.trim(),
        });
    }

    async function handleSubmit(e: any) {
        e.preventDefault();
        try {
            if (userLoginInfo.password !== userLoginInfo.confirmPassword) {
                return setErrorMsg("Passwords are not matching");
            }
            const response = await apiEndpoints.resetClientPassword(
                {
                    clientId: userLoginInfo.clientId,
                    clientSecret: userLoginInfo.clientSecret,
                    password: userLoginInfo.password,
                }
            );
            setErrorMsg(response.data.message);
            setTimeout(() => {
                history.push("/auth/login");
            }, 1000)
        } catch (error: any) {
            setErrorMsg(error.response.data?.message);
        }
    }

    function alertError() {
        if (errorMsg) {
            return (
                <UncontrolledAlert className='alert-default mt-3' fade={false}>
                    <span className='alert-inner--text'>{errorMsg}</span>
                </UncontrolledAlert>
            );
        }
    }

    return (
        <>
            <Col lg='5' md='7'>
                <Card className='bg-secondary shadow border-0'>
                    <CardBody className='px-lg-5 py-lg-5'>
                        <div className='text-center text-muted mb-4'>
                            <small>Reset Password</small>
                        </div>
                        <Form role='form' onSubmit={handleSubmit}>
                            <FormGroup className='mb-3'>
                                <InputGroup className='input-group-alternative'>
                                    <InputGroupAddon addonType='prepend'>
                                        <InputGroupText>
                                            <i className='ni ni-lock-circle-open' />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder='Client ID'
                                        type='text'
                                        autoComplete='clientId'
                                        name='clientId'
                                        onChange={collectData}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup className='mb-3'>
                                <InputGroup className='input-group-alternative'>
                                    <InputGroupAddon addonType='prepend'>
                                        <InputGroupText>
                                            <i className='ni ni-lock-circle-open' />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder='Client Secret'
                                        type='password'
                                        autoComplete='clientSecret'
                                        name='clientSecret'
                                        onChange={collectData}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup className='input-group-alternative'>
                                    <InputGroupAddon addonType='prepend'>
                                        <InputGroupText>
                                            <i className='ni ni-lock-circle-open' />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder='Password'
                                        type='password'
                                        autoComplete='new-password'
                                        name='password'
                                        onChange={collectData}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup className='input-group-alternative'>
                                    <InputGroupAddon addonType='prepend'>
                                        <InputGroupText>
                                            <i className='ni ni-lock-circle-open' />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder='Confirm your Password'
                                        type='password'
                                        autoComplete='new-password'
                                        name='confirmPassword'
                                        onChange={collectData}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <div className='text-center'>
                                <Button className='my-4' color='primary' type='submit'>
                                    Submit
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
                {alertError()}
            </Col>
        </>
    );
};
