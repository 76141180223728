import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { ReportDetails } from "../../components/SubPages/ReportDetails";
import Header from "components/Headers/Header";
import { apiEndpoints } from "services/api/endpoints/apiEndpoints";
import { IReport } from "types/interfaces";
import { useStateContext } from "contexts/contextProvider";
import { UncontrolledAlert } from "reactstrap";

interface Props {
	state: object;
}

export const Reports = (props: Props) => {
	const [alert, setAlert] = useState<string>();
	const [reports, setReports] = useState<IReport[]>([]);
	const [selectedReport, setSelectedReport] = useState<IReport | undefined>(undefined);
	const [page, setPage] = useState<number>(1);
	const { setUser, user } = useStateContext();

	const itemsPerPage = 10;

	const getUser = async () => {
		try {
			const response = await apiEndpoints.getAdmin();
			setUser({ ...response.data.admin, isAdmin: true });
		} catch (error) { }
	}

	const fetchReports = async () => {
		const response = await apiEndpoints.fetchReports();
		if (!user.isAdmin) {
			return setReports(response.data.filter((item: IReport) => item?.clientId === user?.clientId));
		}
		setReports(response.data);
	}

	const selectReport = (id: string) => {
		const foundReport = reports.find((item: IReport) => item._id === id);
		setSelectedReport(foundReport);
	}

	const closeReport = async (id: string) => {
		try {
			await apiEndpoints.closeReport(id);
			fetchReports();
		} catch (error) {
			setAlert("Something went wrong, please try again later");
		}
	}

	useEffect(() => {
		getUser();
		fetchReports();
	}, [])

	const lastIndex = page * itemsPerPage;
	const firstIndex = lastIndex - itemsPerPage;
	const currentItems = reports.slice(firstIndex, lastIndex);

	const paginate = (pageNumber: number) => setPage(pageNumber);

	function alertError() {
		if (alert) {
			return (
				<UncontrolledAlert className="alert-default" fade={false}>
					<span className="alert-inner--text">{alert}</span>
				</UncontrolledAlert>
			);
		}
	}
	return <>
		<Header state={props.state} />
		{alertError()}
		{
			selectedReport
				?
				<ReportDetails report={selectedReport} setSelectedReport={setSelectedReport} />
				:
				<>
					<Table striped bordered hover className="m-3">
						<thead>
							<tr>
								<th>Type</th>
								<th>Priority</th>
								<th>Date</th>
								{user.isAdmin && <th>Client</th>}
								<th>Status</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{currentItems.map((report: any) => (
								<tr key={report.id}>
									<td>{report.issueType}</td>
									<td>{report.issuePriority}</td>
									<td>{report.createdAt.split('T')[0]}</td>
									{user.isAdmin && <td>{report.clientName}</td>}
									<td>{report.isOpen ? "Open" : "Closed"}</td>
									<td>
										<strong onClick={() => selectReport(report._id)} style={{ cursor: "pointer" }}>View Details</strong> <br />
										{
											(user.isAdmin && report.isOpen) &&
											<strong onClick={() => closeReport(report._id)} style={{ cursor: "pointer" }} className="text-danger">Mark as closed</strong>
										}

									</td>
								</tr>
							))}
						</tbody>
					</Table>
					<nav>
						<ul className='pagination ml-3'>
							{Array.from({ length: Math.ceil(reports.length / itemsPerPage) }, (_, i) => (
								<li key={i} className={`page-item ${page === i + 1 ? 'active' : ''}`}>
									<a onClick={() => paginate(i + 1)} className='page-link'>
										{i + 1}
									</a>
								</li>
							))}
						</ul>
					</nav>
				</>
		}
	</>;
};
