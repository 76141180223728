/* eslint-disable no-param-reassign */
import axios from 'axios';
import { Secrets } from 'services/secrets';

const baseURL = Secrets.betaBackEndUrl;

const api = axios.create({ baseURL });

api.interceptors.request.use(
    (config) => {
        try {
            const token = localStorage.getItem("authToken");
            if (config.headers) {
                config.headers.Accept = "application/json";
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
            }
            return config;
        } catch (error: any) {
            return undefined;
        }
    },
    (error) => Promise.reject(error)
);

export default api