import { Index } from 'views/Index';
import { Profile } from 'views/examples/Profile';
import { Esims } from 'views/examples/Esims';
import { Orders } from 'views/examples/Orders';
import { Subscriptions } from 'views/examples/Subscriptions';
import { Login } from 'views/examples/Login';
import { AffiliateLogin } from 'views/examples/AffiliateLogin';
import { Help } from 'views/examples/Help';
import { Reports } from 'views/examples/reports';
import { Plans } from 'views/examples/plans';
import { ResetPassword } from 'views/examples/resetPassword';
import { InsertEsim } from 'views/examples/insertEsim';
import { Affiliate } from 'views/examples/Affiliate';
import { UnusedEsims } from 'views/examples/unusedEsims';

var routes = [
	{
		path: '/index',
		name: 'Dashboard',
		icon: 'ni ni-tv-2 text-primary',
		component: Index,
		layout: '/admin',
	},
	{
		path: '/subscriptions',
		name: 'Subscriptions',
		icon: 'ni ni-basket text-primary',
		component: Subscriptions,
		layout: '/admin',
	},
	{
		path: '/orders',
		name: 'Orders',
		icon: 'ni ni-cart text-primary',
		component: Orders,
		layout: '/admin',
	},
	{
		path: '/esims',
		name: 'eSIMs',
		icon: 'ni ni-world-2 text-primary',
		component: Esims,
		layout: '/admin',
	},
	{
		path: '/user-profile',
		name: 'User Profile',
		icon: 'ni ni-single-02 text-primary',
		component: Profile,
		layout: '/admin',
	},
	{
		path: '/help',
		name: 'Help center',
		icon: 'ni ni-air-baloon text-primary',
		component: Help,
		layout: '/admin',
	},
	{
		path: '/login',
		name: 'Login',
		icon: 'ni ni-key-25 text-info',
		component: Login,
		layout: '/auth',
	},
	{
		path: '/password/reset',
		name: 'Reset password',
		icon: 'ni ni-key-25 text-info',
		component: ResetPassword,
		layout: '/auth',
	},
	{
		path: '/login-affiliate',
		name: 'Affiliate Login',
		icon: 'ni ni-key-25 text-info',
		component: AffiliateLogin,
		layout: '/auth',
	},
	{
		path: '/reports',
		name: 'Reports',
		icon: 'ni ni-bullet-list-67 text-primary',
		component: Reports,
		layout: '/admin',
	},
	{
		path: '/plans',
		name: 'Plans',
		icon: 'ni ni-bullet-list-67 text-primary',
		component: Plans,
		layout: '/admin',
	},
	{
		path: '/affiliate',
		name: 'affiliate',
		icon: 'ni ni-bullet-list-67 text-primary',
		component: Affiliate,
		layout: '/admin',
	},
	{
		path: '/insert-esim',
		name: 'InsertEsim',
		icon: 'ni ni-bullet-list-67 text-primary',
		component: InsertEsim,
		layout: '/admin',
	},
	{
		path: '/unused-esims',
		name: 'UnusedEsims',
		icon: 'ni ni-bullet-list-67 text-primary',
		component: UnusedEsims,
		layout: '/admin',
	},
];
export default routes;