import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Header from "components/Headers/Header";
import { apiEndpoints } from "services/api/endpoints/apiEndpoints";
import { IReport } from "types/interfaces";
import { useStateContext } from "contexts/contextProvider";
import { UncontrolledAlert } from "reactstrap";
import { PropagateLoader } from "react-spinners";

interface Props {
	state: object;
}

const override = {
	display: "block",
	margin: "0 auto",
	borderColor: "red",
};

export const UnusedEsims = (props: Props) => {
	const [alert, setAlert] = useState<string>();
	const [unusedSubscriptions, setUnusedSubscriptions] = useState<any[]>([]);
	const [page, setPage] = useState<number>(1);
	const { setUser, user } = useStateContext();
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const itemsPerPage = 10;

	const getUser = async () => {
		try {
			const response = await apiEndpoints.getAdmin();
			setUser({ ...response.data.admin, isAdmin: true });
		} catch (error) { }
	}

	const fetchUnusedSubscriptions = async () => {
		const response = await apiEndpoints.fetchUnusedSubscriptions();
		setUnusedSubscriptions(response.data.activeSubscriptions);
	}

	const initScreen = async () => {
		try {
			await getUser();
			await fetchUnusedSubscriptions();
		} catch (error) {
			setAlert("Something went wrong please try again later");
		} finally {
			setIsLoading(false);
		}
	}

	const revokeUnusedSubscription = async (simId: string, providerSubscriptionId: string) => {
		try {
			setIsLoading(true);
			await apiEndpoints.revokeUnusedSubscription(simId, providerSubscriptionId);
			setUnusedSubscriptions(unusedSubscriptions.filter(currentItems => currentItems.providerSubscriptionId !== providerSubscriptionId));
		} catch (error) {
			setAlert("Something went wrong please try again later");
		} finally {
			setIsLoading(false);
		}
	}

	useEffect(() => {
		initScreen();
	}, []);

	const lastIndex = page * itemsPerPage;
	const firstIndex = lastIndex - itemsPerPage;
	const currentItems = unusedSubscriptions.slice(firstIndex, lastIndex);

	const paginate = (pageNumber: number) => {
		setPage(pageNumber);
	};

	function alertError() {
		if (alert) {
			return (
				<UncontrolledAlert className="alert-default" fade={false}>
					<span className="alert-inner--text">{alert}</span>
				</UncontrolledAlert>
			);
		}
	}

	return (
		<>
			<Header state={props.state} />
			{alertError()}
			{isLoading ? (
				<div className="loader">
					<PropagateLoader
						color={"#8965e0"}
						loading={isLoading}
						cssOverride={override}
						size={40}
						aria-label="Loading Spinner"
						data-testid="loader"
					/>
				</div>
			) : (
				<>
					<Table striped bordered hover className="m-3">
						<thead>
							<tr>
								<th>Email</th>
								<th>Name</th>
								<th>Iccid</th>
								<th>Product code</th>
								<th>Offer Id</th>
								<th>Sold price</th>
								<th>Creation date</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{currentItems.map((subscription: any) => (
								<tr key={subscription._id}>
									<td>{subscription.userEmail}</td>
									<td>{subscription.name}</td>
									<td>{subscription.simId}</td>
									<td>{subscription.productCode}</td>
									<td>{subscription.providerSubscriptionId}</td>
									<td>{subscription.soldProductPrice} {subscription.soldProductCurrency}</td>
									<td>{subscription.actualSubscriptionCreatedAt.split('T')[0]}</td>
									<td>
										<button
											className="btn btn-danger"
											onClick={() => revokeUnusedSubscription(subscription.simId, subscription.providerSubscriptionId)}
										>
											REVOKE
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
					<nav>
						<ul className='pagination ml-3'>
							{Array.from({ length: Math.ceil(unusedSubscriptions.length / itemsPerPage) }, (_, i) => (
								<li key={i} className={`page-item ${page === i + 1 ? 'active' : ''}`}>
									<a href="#" onClick={() => paginate(i + 1)} className='page-link'>
										{i + 1}
									</a>
								</li>
							))}
						</ul>
					</nav>
				</>
			)}
		</>
	);
};
