import axios from 'axios';
import { Secrets } from 'services/secrets';

const baseURL = Secrets.zimServicesUrl + "/auth";
const authApiConfig = axios.create({ baseURL });

authApiConfig.interceptors.request.use(
	(config) => {
		if (config.headers) config.headers.Accept = 'application/json';
		return config;
	},
	(error) => Promise.reject(error)
);

export default authApiConfig;
