import { render } from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import 'assets/plugins/nucleo/css/nucleo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/css/style.css';
import 'assets/scss/argon-dashboard-react.scss';

import AdminLayout from 'layouts/Admin';
import AuthLayout from 'layouts/Auth';
import { ContextProvider } from 'contexts/contextProvider';

const root =  document.getElementById('root') as HTMLElement
render(
	<ContextProvider>
		<BrowserRouter>
			<Switch>
				<Route path='/admin' render={(props) => <AdminLayout {...props} />} />
				<Route path='/auth' render={(props) => <AuthLayout {...props} />} />
				<Redirect from='/' to='/admin/login' />
			</Switch>
		</BrowserRouter>
	</ContextProvider>
	,
	root
);
