export enum Provider {
    TRUPHONE = 'TRUPHONE',
    ESIMGO = 'ESIMGO',
    TRANSATEL = 'TRANSATEL',
    ORANGE = 'ORANGE',
}

export enum SubscriptionStatus {
    ACTIVE = 'ACTIVE',
    EXPIRED = 'EXPIRED',
    PENDING = 'PENDING',
    DEPLETED = 'DEPLETED',
}

export enum Currency {
    EUR = 'EUR',
    USD = 'USD',
    GBP = 'GBP',
}

export enum OrderStatus {
    ACCEPTED = 'ACCEPTED',
    FULFILLING = 'FULFILLING',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED'
}

export interface OrderOutput {
    iccid: string;
    subscription_id: string;
}

export enum eSimStatus {
    Released = 'Released',
    Installed = 'Installed',
    Unavailable = 'Unavailable',
    Downloaded = 'Downloaded'
}

export enum IssueType {
    EndUserIssueEscalation = 'End user issue escalation',
    APIIssue = 'API issue',
    GeneralEnquiry = 'General enquiry',
}

export enum IssuePriority {
    LOW = "Low",
    NORMAL = "Normal",
    HIGHT = "High",
    URGENT = "Urgent"
}

export enum EsimProvider {
    TRUPHONE = 'TRUPHONE',
    ESIMGO = 'ESIMGO',
    WTR = 'WTR',
    ORANGE = 'ORANGE'
}

export enum PlanScope {
    GLOBAL = 'GLOBAL',
    LOCAL = 'LOCAL',
    REGIONAL = 'REGIONAL',
}

export enum EsimType {
    DATA = 'DATA',
    VOICE = 'VOICE',
    VOICE_AND_DATE = 'VOICE_AND_DATA'
}