import { useState } from "react";
import classnames from "classnames";

import { NavItem, NavLink, Nav } from "reactstrap";

interface item {
    label: string;
    id: number;
}

interface Props {
    data: item[];
    setSelectedHelpOption: any;
}

export const HelpNav = (props: Props) => {
    const [navPills, setNavPills] = useState();
    function toggleNavs(e: any, state: string, index: any, helpOption: string) {
        e.preventDefault();
        setNavPills(index);
        props.setSelectedHelpOption(helpOption);
    }
    return <Nav
        className="nav-fill flex-column flex-sm-row"
        id="tabs-text"
        pills
        role="tablist"
    >
        {props.data.map((item, index) => (
            <NavItem>
                <NavLink
                    aria-selected={navPills === index}
                    className={classnames("mb-sm-3 mb-md-0", {
                        active: navPills === index,
                    })}
                    onClick={(e) => toggleNavs(e, "navPills", index, item.label)}
                    href="#pablo"
                    role="tab"
                    key={item.id}
                >
                    {item.label}
                </NavLink>
            </NavItem>
        ))}
    </Nav>
};
