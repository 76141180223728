import { useState } from "react";
import Header from "components/Headers/Header";
import { HelpNav } from "components/Navbars/helpNav";
import { HelpOptions } from "components/forms/FormRenderer";
import { RenderFormBasedOnValue } from "components/forms/FormRenderer";
import { UncontrolledAlert } from "reactstrap";

interface Props {
	state: object;
}

export const Help = (props: Props) => {
	const [errorMsg, setErrorMsg] = useState<string>();
	const [selectedHelpOption, setSelectedHelpOption] = useState<HelpOptions>(
		HelpOptions.default
	);
	const helpOptions = [
		{
			label: "End user issue escalation",
			id: 223442,
		},
		{
			label: "API issue",
			id: 443268,
		},
		{
			label: "General enquiry",
			id: 322344,
		},
	];
	const checkboxQuestions = [
		{
			title: "Device is eSIM Compatible",
			label: "I have confirmed the end user device is eSIM compatible",
		},
		{
			title: "Data Roaming Enabled",
			label: "I confirm I have checked the end user has data roaming enabled",
		},
		{
			title: "Device is carrier unlocked",
			label: "I have confirmed the end user device is carrier unlocked",
		},
		{
			title: "Stable internet connection",
			label:
				"I confirm I have checked that the end-user device has a stable Wifi or 4G connection",
		},
		{
			title: "APN Settings",
			label:
				"I confirm I have checked the APN settings are correct on the end user device",
		},
		{
			title: "Bundle Applied",
			label:
				"I confirm I have checked a bundle is applied to the eSIM for the country the end user is located in",
		},
	];
	function alertError() {
		if (errorMsg) {
			return (
				<UncontrolledAlert className="alert-default" fade={false}>
					<span className="alert-inner--text">{errorMsg}</span>
				</UncontrolledAlert>
			);
		}
	}
	return <>
		<Header state={props.state} />
		<div className="help-center m-5">
			<p>Please choose from the below options </p>
			<HelpNav
				data={helpOptions}
				setSelectedHelpOption={setSelectedHelpOption}
			/>
			<RenderFormBasedOnValue
				value={selectedHelpOption}
				checkboxQuestions={checkboxQuestions}
				setErrorMsg={setErrorMsg}
			/>
		</div>
		{alertError()}
	</>;
};

export const formatBytes = (bytes: number, hasUnit: boolean = true): string | number => {
	const unites = ['Bytes', 'KB', 'MB', 'GB'];
	if (bytes === 0) {
		return '0 Bytes';
	}
	const i = Math.floor(Math.log(bytes) / Math.log(1024));

	if (hasUnit) {
		if (unites[i] === 'MB') {
			return parseInt((bytes / Math.pow(1024, i)).toFixed());
		}
		return parseFloat((bytes / Math.pow(1024, i)).toFixed(2));
	}

	if (unites[i] === 'MB') {
		return parseInt((bytes / Math.pow(1024, i)).toFixed()) + ' ' + unites[i];
	}
	return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + unites[i];
};

export const formatDate = (date: Date): string => {
	const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	const monthIndex = date.getMonth();
	const year = date.getFullYear().toString();

	const day = date.getDate();
	const monthName = monthNames[monthIndex];
	return `${monthName} ${day},  ${year}`;
};
