import { Link } from 'react-router-dom';
// reactstrap components
import {
    UncontrolledCollapse,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
} from 'reactstrap';

const AdminNavbar = () => {
    return (
        <>
            <Navbar className='navbar-top navbar-horizontal navbar-dark' expand='md'>
                <Container className='px-4'>
                    <NavbarBrand to='/' tag={Link}>
                        <img
                            alt='...'
                            src={require('../../assets/img/brand/argon-react-white.png')}
                        />
                    </NavbarBrand>
                    <button className='navbar-toggler' id='navbar-collapse-main'>
                        <span className='navbar-toggler-icon' />
                    </button>
                    <UncontrolledCollapse navbar toggler='#navbar-collapse-main'>
                        <div className='navbar-collapse-header d-md-none'>
                            <Row>
                                <Col className='collapse-brand' xs='6'>
                                    <Link to='/'>
                                        <img
                                            alt='...'
                                            src={require('../../assets/img/brand/Logotype_purple.png')}
                                        />
                                    </Link>
                                </Col>
                                <Col className='collapse-close' xs='6'>
                                    <button className='navbar-toggler' id='navbar-collapse-main'>
                                        <span />
                                        <span />
                                    </button>
                                </Col>
                            </Row>
                        </div>
                        <Nav className='ml-auto' navbar>
                            <NavItem>
                                <NavLink className='nav-link-icon' to='/' tag={Link}>
                                    <i className='ni ni-planet' />
                                    <span className='nav-link-inner--text'>Dashboard</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className='nav-link-icon' to='/auth/login' tag={Link}>
                                    <i className='ni ni-planet' />
                                    <span className='nav-link-inner--text'>Partner</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className='nav-link-icon' to='/auth/login-affiliate' tag={Link}>
                                    <i className='ni ni-planet' />
                                    <span className='nav-link-inner--text'>Affiliate</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className='nav-link-icon'
                                    to='/admin/user-profile'
                                    tag={Link}
                                >
                                    <i className='ni ni-single-02' />
                                    <span className='nav-link-inner--text'>Profile</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </UncontrolledCollapse>
                </Container>
            </Navbar>
        </>
    );
};

export default AdminNavbar;
