import { useState, useEffect, useRef, useCallback } from 'react';
import {
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	DropdownToggle,
	Dropdown,
	Card,
	CardHeader,
	CardFooter,
	Table,
	Container,
	Row,
	UncontrolledAlert,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	Button,
	Form,
	FormGroup,
	Input,
	Label,
} from 'reactstrap';
import Header from 'components/Headers/Header';
import { apiEndpoints } from '../../services/api/endpoints/apiEndpoints';
import Pagination from '../../components/Pagination/Pagination';
import { IZimSubscription } from '../../types/interfaces';
import PropagateLoader from "react-spinners/PropagateLoader";
import { formatBytes, formatDate } from './Help';
import { useStateContext } from 'contexts/contextProvider';
import { EsimProvider, eSimStatus } from 'types/enums';

const override = {
	display: "block",
	margin: "0 auto",
	borderColor: "red",
};

export enum SubscriptionStatus {
	ACTIVE = 'ACTIVE',
	EXPIRED = 'EXPIRED',
	PENDING = 'PENDING',
	DEPLETED = 'DEPLETED',
}

export enum SubscriptionSortBy {
	CREATION_DATE = 'createdAt',
	ACTIVATION_DATE = 'activationDate',
}

interface Props {
	state: object;
}

export const Subscriptions = (props: Props) => {
	const { setUser, user } = useStateContext();
	const [subscriptions, setSubscriptions] = useState<IZimSubscription[]>();
	const [filter, setFilter] = useState<string | null>(null);
	const [sortBy, setSortBy] = useState<string | null>(null);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [sortDropdownOpen, setSortDropdownOpen] = useState(false);
	const [page, setPage] = useState(1);
	const [errorMsg, setErrorMsg] = useState<string>();
	const [isLoading, setIsloading] = useState<boolean>(true);
	const [editingSubscription, setEditingSubscription] = useState<IZimSubscription | null>(null);
	const [isTopupConfirmationShown, setIsTopupConfirmationShown] = useState<boolean>(false);
	const [firstName, setFirstName] = useState<string>('');
	const [lastName, setLastName] = useState<string>('');
	const allPages = useRef<number[]>([]);
	const itemsPerPage = useRef(10);
	const [selectedSubscription, setSelectedSubscription] = useState<IZimSubscription | null>(null);
	const [isTopupSuccessful, setIsTopupSuccessful] = useState(false);
	const [isTopupLoading, setIsTopupLoading] = useState(false);

	const toggleDropdown = () => setDropdownOpen(prevState => !prevState);
	const toggleSortDropdown = () => setSortDropdownOpen(prevState => !prevState);

	const getSubscriptions = useCallback(async () => {
		try {
			setIsloading(true)
			setErrorMsg('');
			let params: any = {
				pageSize: itemsPerPage.current,
				page,
				sortBy: sortBy ? sortBy : SubscriptionSortBy.CREATION_DATE
			};
			if (filter) {
				params = { ...params, status: filter }
			}
			if (sortBy) {
				params = { ...params, sortBy }
			}
			const response = await apiEndpoints.fetchSubscriptions(params);
			const pages = [];
			for (
				let i = 1;
				i <= Math.ceil(response.data.total / itemsPerPage.current);
				i++
			) {
				pages.push(i);
			}
			allPages.current = pages;
			const updatedSubscriptionsPromises = response.data.subscriptions.map(
				async (subscription: IZimSubscription) => {
					const foundEsim = await apiEndpoints.fetchSingleClientEsims(subscription.simId);
					const isSubscriptionExpired = subscription.status === SubscriptionStatus.EXPIRED || subscription.status === SubscriptionStatus.DEPLETED;
					const status = isSubscriptionExpired ?
						SubscriptionStatus.EXPIRED :
						(subscription.status === SubscriptionStatus.ACTIVE && foundEsim.data.status !== eSimStatus.Installed && subscription.initialBalance === subscription.currentBalance ?
							"PENDING" :
							"ACTIVE"
						)
					return { ...subscription, status }
				}
			)
			const updatedSubscriptions = await Promise.all(updatedSubscriptionsPromises);
			setSubscriptions(updatedSubscriptions);
			setIsloading(false)
		} catch (error: any) {
			setErrorMsg(error.response.data?.message);
		} finally {
			if (selectedSubscription) {
				setIsTopupConfirmationShown(false);
				setIsTopupSuccessful(false);
				setSelectedSubscription(null);
			}
		}
	},
		[
			page,
			filter,
			sortBy,
		]
	)

	async function expireSubscription(e: any, id: string) {
		try {
			setErrorMsg('');
			e.preventDefault();
			await apiEndpoints.expireSubscription(id);
			setErrorMsg("Subscription expired successfully");
		} catch (error: any) {
			setErrorMsg(error.response.data?.message);
		}
	}

	async function refundSubscription(e: any, id: string) {
		try {
			setErrorMsg('');
			e.preventDefault();
			await apiEndpoints.refundSubscription(id);
			setErrorMsg("Subscription refunded successfully");
		} catch (error: any) {
			setErrorMsg(error.response.data?.message);
		}
	}

	async function handleTopupSubscription() {
		try {
			setErrorMsg('');
			if (selectedSubscription) {
				setIsTopupLoading(true);
				await apiEndpoints.topupSubscription(selectedSubscription?._id);
				setIsTopupSuccessful(true);
			}

		} catch (error: any) {
			setErrorMsg(error.response.data?.message);
		} finally {
			setIsTopupLoading(false);
		}
	}

	function alertError() {
		if (errorMsg) {
			return (
				<UncontrolledAlert className='alert-default' fade={false}>
					<span className='alert-inner--text'>{errorMsg}</span>
				</UncontrolledAlert>
			);
		}
	}

	async function updateSubscription() {
		try {
			setErrorMsg('');
			if (editingSubscription) {
				await apiEndpoints.updateSubscription(editingSubscription._id, firstName, lastName);
				setEditingSubscription(null);
				getSubscriptions(); // Fetch the latest data
				setErrorMsg('Subscription updated successfully');
			}
		} catch (error: any) {
			setErrorMsg(error.response.data?.message);
		}
	};

	function startEditingSubscription(subscription: IZimSubscription) {
		setFirstName(subscription.subscriberFirstName || '');
		setLastName(subscription.subscriberLastName || '');
		setEditingSubscription(subscription);
	};


	useEffect(
		() => {
			getSubscriptions();
		},
		[getSubscriptions]
	);

	const isAramco = process.env.REACT_APP_ARAMCO_CLIENT_ID;

	return (
		<>
			<Header state={props.state} />
			{/* Page content */}
			{
				isLoading
					?
					<div className="loader">
						<PropagateLoader
							color={"#8965e0"}
							loading={isLoading}
							cssOverride={override}
							size={40}
							aria-label="Loading Spinner"
							data-testid="loader"
						/>
					</div>
					:
					<Container className='mt--7' fluid>
						{/* Table */}
						{alertError()}
						<Row>
							<div className='col'>
								<Card className='shadow'>
									<CardHeader className='border-0'>
										<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
											<h3 className='mb-0'>Subscriptions</h3>
											<div>
												<Dropdown isOpen={sortDropdownOpen} toggle={toggleSortDropdown}>
													<DropdownToggle caret>
														{!sortBy ? "Sort" : sortBy}
													</DropdownToggle>
													<DropdownMenu right>
														<DropdownItem onClick={() => setSortBy(SubscriptionSortBy.CREATION_DATE)}>CREATION DATE</DropdownItem>
														<DropdownItem onClick={() => setSortBy(SubscriptionSortBy.ACTIVATION_DATE)}>ACTIVATION DATE</DropdownItem>
													</DropdownMenu>
												</Dropdown>
												<Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
													<DropdownToggle caret>
														{!filter ? "Filter" : filter}
													</DropdownToggle>
													<DropdownMenu right>
														<DropdownItem onClick={() => setFilter(null)}>ALL</DropdownItem>
														<DropdownItem onClick={() => setFilter(SubscriptionStatus.ACTIVE)}>{SubscriptionStatus.ACTIVE}</DropdownItem>
														<DropdownItem onClick={() => setFilter(SubscriptionStatus.EXPIRED)}>{SubscriptionStatus.EXPIRED}</DropdownItem>
														<DropdownItem onClick={() => setFilter(SubscriptionStatus.PENDING)}>{SubscriptionStatus.PENDING}</DropdownItem>
														<DropdownItem onClick={() => setFilter(SubscriptionStatus.DEPLETED)}>{SubscriptionStatus.DEPLETED} (All data consumed)</DropdownItem>
													</DropdownMenu>
												</Dropdown>
											</div>
										</div>
									</CardHeader>
									<Table className='align-items-center table-flush' style={{ textAlign: "center" }} responsive>
										<thead className='thead-light'>
											<tr>
												{!isAramco && <th scope='col'>EMAIL</th>}
												<th scope='col'>PROVIDER</th>
												<th scope='col'>FIRST NAME</th>
												<th scope='col'>LAST NAME</th>
												<th scope='col'>PLAN</th>
												<th scope='col'>SIM ID</th>
												<th scope='col'>CREATION DATE</th>
												<th scope='col'>CONSUMPTION (REMAINING DATA)</th>
												<th scope='col'>ACTIVATION DATE</th>
												<th scope='col'>EXPIRATION DATE</th>
												<th scope='col'>STATUS</th>
												<th scope='col'>QRCODE</th>
												{
													process.env.REACT_APP_ARAMCO_CLIENT_ID === user.clientId ? null :
														<>
															<th scope='col'>REFUNDED</th>
														</>
												}

												<th scope='col' />
											</tr>
										</thead>
										<tbody>
											{
												subscriptions?.map(
													(subscription: IZimSubscription) => {
														return (
															<tr>
																{!isAramco && <th scope='row'>{subscription.userEmail}</th>}
																<td>{subscription.provider}</td>
																<td>{subscription.subscriberFirstName ? subscription.subscriberFirstName : "___"}</td>
																<td>{subscription.subscriberLastName ? subscription.subscriberLastName : "___"}</td>
																<td>{subscription.name}</td>
																<td>{subscription.simId}</td>
																<td>{subscription.createdAt ? formatDate(new Date(subscription.createdAt)) : "N/A"}</td>
																{
																	subscription.provider === "ORANGE" ?
																		<>
																			<td>N/A</td>
																			<td>dial #123# to check</td>
																		</>
																		:
																		<>
																			<td>{formatBytes(Number(subscription.currentBalance), false)} / {formatBytes(Number(subscription.initialBalance), false)}</td>
																			<td>{subscription.activationDate?.length > 1 ? formatDate(new Date(subscription.activationDate)) : 'N/A'}</td>
																		</>
																}
																<td>{subscription.expiryDate?.length > 1 ? formatDate(new Date(subscription.expiryDate)) : 'N/A'}</td>
																<td>{subscription.status}</td>
																<td>
																	<a
																		href={`https://zim-services.s3.amazonaws.com/zim-qrcodes/${user._id}/${subscription.simId}.png`}
																		target="_blank"
																		rel="noreferrer"
																	>
																		Visit
																	</a>
																</td>
																{
																	isAramco === user.clientId ? null :
																		<>
																			<td>{subscription.refunded === false ? <i className='ni ni-fat-delete' /> : <i className='ni ni-check-bold' />}</td>
																		</>
																}
																<td className='text-right'>
																	<UncontrolledDropdown>
																		<DropdownToggle
																			className='btn-icon-only text-light'
																			href='#pablo'
																			role='button'
																			size='sm'
																			color=''
																			onClick={(e) => e.preventDefault()}
																		>
																			<i className='fas fa-ellipsis-v' />
																		</DropdownToggle>
																		<DropdownMenu className='dropdown-menu-arrow' right>
																			{
																				subscription?.provider === EsimProvider.ESIMGO && <DropdownItem
																					onClick={
																						() => {
																							setIsTopupConfirmationShown(true);
																							setSelectedSubscription(subscription)
																						}
																					}
																				>
																					TOP-UP PLAN
																				</DropdownItem>
																			}
																			{subscription.provider === EsimProvider.ESIMGO && subscription.status === SubscriptionStatus.PENDING && <DropdownItem
																				onClick={(e) =>
																					refundSubscription(e, subscription._id)
																				}
																			>
																				REFUND
																			</DropdownItem>}
																			<DropdownItem
																				onClick={() => startEditingSubscription(subscription)}
																			>
																				EDIT
																			</DropdownItem>
																		</DropdownMenu>
																	</UncontrolledDropdown>
																</td>
															</tr>
														);
													}
												)
											}
										</tbody>
									</Table>
									<CardFooter className='py-4'>
										<nav aria-label='...'>
											<Pagination
												allPages={allPages.current}
												itemsPerPage={itemsPerPage.current}
												page={page}
												setPage={setPage}
											/>
										</nav>
									</CardFooter>
								</Card>
							</div>
						</Row>
						<Modal style={{ paddingBottom: 20 }} isOpen={isTopupConfirmationShown} toggle={() => setIsTopupConfirmationShown(!isTopupConfirmationShown)}>
							<ModalHeader toggle={() => setIsTopupConfirmationShown(!isTopupConfirmationShown)}>
								<p style={{ fontSize: 18, fontWeight: 'bold' }}>Top-up Confirmation</p>
							</ModalHeader>
							<ModalBody>
								{
									isLoading
										? 'Processing...' :
										isTopupSuccessful ?
											'Topup is successfully added to the eSIM.' :
											`Are you sure you want to add a topup ${selectedSubscription?.name} for ${selectedSubscription?.simId}?`
								}
							</ModalBody>

							{
								isTopupSuccessful
									? <Button color="primary" style={{ margin: "15px auto", width: '40%' }} onClick={getSubscriptions}>Continue</Button> :
									isTopupLoading ?
										<>
											<div className="lds-dual-ring" />
										</>
										: <>
											<Button style={{ margin: "0 auto", width: '40%' }} color="primary" onClick={handleTopupSubscription}>Yes</Button>{' '}
											<Button style={{ margin: "15px auto", width: '40%' }} color="secondary" onClick={() => setIsTopupConfirmationShown(false)}>No</Button>
										</>
							}
						</Modal>


						<Modal isOpen={!!editingSubscription} toggle={() => setEditingSubscription(null)}>
							<ModalHeader toggle={() => setEditingSubscription(null)}>Edit Subscription</ModalHeader>
							<ModalBody>
								<Form>
									<FormGroup>
										<Label for="firstName">First Name</Label>
										<Input
											type="text"
											id="firstName"
											value={firstName}
											onChange={e => setFirstName(e.target.value)}
										/>
									</FormGroup>
									<FormGroup>
										<Label for="lastName">Last Name</Label>
										<Input
											type="text"
											id="lastName"
											value={lastName}
											onChange={e => setLastName(e.target.value)}
										/>
									</FormGroup>
								</Form>
							</ModalBody>
							<ModalFooter>
								<Button color="primary" onClick={updateSubscription}>Save</Button>{' '}
								<Button color="secondary" onClick={() => setEditingSubscription(null)}>Cancel</Button>
							</ModalFooter>
						</Modal>
					</Container>
			}
		</>
	);
};
