import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import IUser, { IClient, IOrder } from '../types/interfaces';
export type StateContextType = {
	user: any,
	setUser: Dispatch<SetStateAction<any>>,
	isUserLoggedIn: boolean,
	setIsUserLoggedIn: Dispatch<SetStateAction<boolean>>,
	orders: IOrder[],
	setOrders: Dispatch<SetStateAction<any[]>>,
	eSimsIccids: any[],
	setESimsIccids: Dispatch<SetStateAction<any[]>>,
	promotions: any[],
	setPromotions: Dispatch<SetStateAction<any[]>>,
};

const initialContextData: StateContextType = {
	user: {},
	setUser: () => { },
	isUserLoggedIn: false,
	setIsUserLoggedIn: () => { },
	orders: [],
	setOrders: () => { },
	eSimsIccids: [],
	setESimsIccids: () => { },
	promotions: [],
	setPromotions: () => { },
}

const StateContext = createContext<StateContextType>(initialContextData);

export const ContextProvider = ({ children }: any) => {
	const [user, setUser] = useState({} as IClient | IUser);
	const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
	const [orders, setOrders] = useState<any[]>([]);
	const [eSimsIccids, setESimsIccids] = useState<any[]>([]);
	const [promotions, setPromotions] = useState<any[]>([]);
	return (
		<StateContext.Provider
			value={{
				user,
				setUser,
				isUserLoggedIn,
				setIsUserLoggedIn,
				orders,
				setOrders,
				eSimsIccids,
				setESimsIccids,
				promotions,
				setPromotions
			}}
		>
			{children}
		</StateContext.Provider>
	);
};

export const useStateContext = () => useContext(StateContext);
