import { Button, Container, Row, Col } from "reactstrap";

const UserHeader = (props: any) => {
	const isAffiliateUserLoggedIn = localStorage.getItem('isAffiliateUserLoggedIn');
	return (
		<>
			<div
				className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
				style={{
					minHeight: "600px",
					backgroundSize: "cover",
					backgroundPosition: "center top"
				}}
			>
				{/* Mask */}
				<span className="mask bg-gradient-default opacity-8" />
				{/* Header container */}
				<Container className="d-flex align-items-center" fluid>
					<Row>
						<Col lg="7" md="10">
							<h1 className="display-2 text-white">Hello {isAffiliateUserLoggedIn ? props.user?.firstName : props.user.name}</h1>
							<p className="text-white mt-0 mb-5">
								This is your profile page. You can see the info that we have about you here
							</p>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default UserHeader;
