import { useRef } from 'react';
import {
	CardFooter,
	Pagination,
	PaginationItem,
	PaginationLink,
} from 'reactstrap';

const PaginationComponent = (props: any) => {
	const maxPageNumberLimit = useRef(10);
	const minPageNumberLimit = useRef(0);
	const lastPage = props.allPages[props.allPages.length - 1];
	const renderPageNumbers = props.allPages.map((pageNumber: any) => {
		if (
			pageNumber < maxPageNumberLimit.current + 1 &&
			pageNumber > minPageNumberLimit.current
		) {
			return (
				<PaginationItem
					className={Number(props.page) === pageNumber ? 'active' : undefined}
					key={pageNumber}
					id={pageNumber}
				>
					<PaginationLink href='#pablo' onClick={(e) => handleClick(e)}>
						{pageNumber}
					</PaginationLink>
				</PaginationItem>
			);
		} else {
			return null;
		}
	});

	function handleClick(e: any) {
		e.preventDefault();
		props.setPage(Number(e.target.text));
	}

	function handleNextbtn(e: any) {
		e.preventDefault();
		props.setPage(Number(props.page) + 1);

		if (props.page + 1 > maxPageNumberLimit.current) {
			maxPageNumberLimit.current =
				maxPageNumberLimit.current + props.itemsPerPage;
			minPageNumberLimit.current =
				minPageNumberLimit.current + props.itemsPerPage;
		}
	}

	function handlePrevbtn(e: any) {
		e.preventDefault();
		props.setPage(props.page - 1);

		if ((props.page - 1) % props.itemsPerPage === 0) {
			maxPageNumberLimit.current =
				maxPageNumberLimit.current - props.itemsPerPage;
			minPageNumberLimit.current =
				minPageNumberLimit.current - props.itemsPerPage;
		}
	}

	return (
		<CardFooter className='py-4'>
			<nav aria-label='...'>
				<Pagination
					className='pagination justify-content-end mb-0'
					listClassName='justify-content-end mb-0'
				>
					<PaginationItem className={props.page === 1 ? 'disabled' : ''}>
						<PaginationLink
							href='#pablo'
							onClick={(e) => handlePrevbtn(e)}
							tabIndex={-1}
						>
							<i className='fas fa-angle-left' />
							<span className='sr-only'>Previous</span>
						</PaginationLink>
					</PaginationItem>
					{renderPageNumbers}
					<PaginationItem
						className={props.page === lastPage ? 'disabled' : ''}
					>
						<PaginationLink href='#pablo' onClick={(e) => handleNextbtn(e)}>
							<i className='fas fa-angle-right' />
							<span className='sr-only'>Next</span>
						</PaginationLink>
					</PaginationItem>
				</Pagination>
			</nav>
		</CardFooter>
	);
};

export default PaginationComponent;
