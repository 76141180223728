import axios from 'axios';
import { Secrets } from 'services/secrets';


const baseURL = Secrets.betaBackEndUrl + '/auth';

const betaAuthApiConfig = axios.create({ baseURL });
betaAuthApiConfig.interceptors.request.use(
    (config) => {
        if (config.headers) config.headers.Accept = 'application/json';
        return config;
    },
    (error) => Promise.reject(error)
);

export default betaAuthApiConfig;
