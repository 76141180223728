import { useState, useEffect, useCallback } from "react";
import classnames from "classnames";
import Chart from "chart.js";
import { Line, Bar } from "react-chartjs-2";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Table,
    Container,
    Row,
    Col,
    UncontrolledAlert
} from "reactstrap";

import {
    chartOptions,
    parseOptions,
    chartExample1,
    chartExample2,
} from "variables/charts";
import Header from "components/Headers/Header";
import { AdminDashboard } from "components/SubPages/AdminDashboard";
import { useStateContext } from "../contexts/contextProvider";
import { betaApi } from '../services/api/endpoints/betaApiEndpoints';
import { apiEndpoints } from '../services/api/endpoints/apiEndpoints';

export const Index = (props) => {
    const [activeNav, setActiveNav] = useState(1);
    const [affiliateCommission, setAffiliateCommission] = useState({});
    const [chartExample1Data, setChartExample1Data] = useState("data1");
    const isAffiliateUserLoggedIn = localStorage.getItem('isAffiliateUserLoggedIn');
    const isAgentLoggedIn = localStorage.getItem('isAgentLoggedIn');
    const { orders, promotions, setPromotions, setUser, user } = useStateContext();
    const [selectedClient, setSelectedClient] = useState(null);
    const [agentUsers, setAgentUsers] = useState([]);
    const [agentPurchases, setAgentPurchases] = useState([]);
    const [state, setState] = useState();
    const [indexState, setIndexState] = useState({});
    const [errorMsg, setErrorMsg] = useState();

    if (window.Chart) {
        parseOptions(Chart, chartOptions());
    }

    const toggleNavs = (e, index) => {
        e.preventDefault();
        setActiveNav(index);
        setChartExample1Data("data" + index);
    };

    function alertError() {
        if (errorMsg) {
            return (
                <UncontrolledAlert className='alert-default' fade={false}>
                    <span className='alert-inner--text'>{errorMsg}</span>
                </UncontrolledAlert>
            );
        }
    }

    const getAffiliateDetails = async () => {
        try {
            const response = await betaApi.getAffiliationDetails();
            setPromotions(response.data)
        } catch (error) {
            setPromotions([]);
            setErrorMsg(error.response?.data?.message);
        }
    }

    const getAgentDetails = async () => {
        try {
            const response = await betaApi.getAgentDetails();
            setAgentUsers(response.data);
            const allPurchases = response.data.flatMap(user => user.purchases?.map(purchase => ({
                        ...purchase,
                        userEmail: user.email,
                        firstName:user.firstName 
                    }))
            );
            setAgentPurchases(allPurchases);
        } catch (error) {
            console.log("error : ",error);
            setAgentUsers([]);
            setAgentPurchases([]);
            setErrorMsg(error.response?.data?.message || 'An error occurred');
        }
    }
    

    const getAffiliateMarketerProfile = async () => {
        try {
            const response = await betaApi.getAffiliateMarketerProfile();
            const commissions = {};
            for (const commission of response.data.affiliateCommissions) {
                commissions[commission.provider] = commission.amount;
            }
            setAffiliateCommission(commissions);
            setUser(response.data);
        } catch (error) {
            setAffiliateCommission([]);
            setErrorMsg(error.response?.data?.message);
        }
    }

    const getUser = async () => {
        try {
            const response = await Promise.any(
                [
                    apiEndpoints.getAdmin(),
                    apiEndpoints.getClient(),
                ]
            )
            setUser(response.data.client ? response.data.client : { ...response.data.admin, isAdmin: true });
        } catch (error) { }
    }

    const getSelectedClientStatistic = async () => {
        const [orderStatistics, saleStatistics] = await Promise.all(
            [
                props.state.getOrdersAndEsims(selectedClient?.clientId),
                props.state.getSalesValue(selectedClient?.clientId),
            ]
        )
        setState({ ...orderStatistics, ...saleStatistics });
    }

    const calculatePromotionUsageAndRevenue = useCallback(() => {
        const now = new Date();
        const beginningOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const beginningOfLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        const beginningOfBeforeLastMonth = new Date(now.getFullYear(), now.getMonth() - 2, 1);

        const totalUsage = [];
        for (const promotion of promotions) {
            if (promotion.affiliateCommissions) {
                promotion.consumption = promotion.consumption.map(i => {
                    return {...i, affiliateCommissions:promotion.affiliateCommissions}
                })
            }
            totalUsage.push(...promotion.consumption);
        }
        const currentMonthPromotionUsage = totalUsage.filter(usage => {
            return new Date(usage.purchaseDate) > beginningOfMonth;
        });

        const lastMonthPromotionUsage = totalUsage.filter(usage => {
            return new Date(usage.purchaseDate) > beginningOfLastMonth && new Date(usage.purchaseDate) < beginningOfMonth;
        });

        const beforeLastMonthPromotionUsage = totalUsage.filter(usage => {
            return new Date(usage.purchaseDate) > beginningOfBeforeLastMonth && new Date(usage.purchaseDate) < beginningOfLastMonth;
        });

        const getSum = (total, num) => total + num;

        const currentMonthRevenue = currentMonthPromotionUsage
            .map(usage => {
                const commissions = {};
                for (const commission of usage.affiliateCommissions) {
                    commissions[commission.provider] = commission.amount;
                }
                return Number(usage.plan.priceGbp) * commissions[usage.plan.provider] / 100
            })
            .reduce(getSum, 0);

        const lastMonthRevenue = lastMonthPromotionUsage
            .map(usage => {
                const commissions = {};
                for (const commission of usage.affiliateCommissions) {
                    commissions[commission.provider] = commission.amount;
                }
                return Number(usage.plan.priceGbp) * commissions[usage.plan.provider] / 100
            })
            .reduce(getSum, 0);

        const beforeLastMonthRevenue = beforeLastMonthPromotionUsage
            .map(usage => {
                const commissions = {};
                for (const commission of usage.affiliateCommissions) {
                    commissions[commission.provider] = commission.amount;
                }
                return Number(usage.plan.priceGbp) * commissions[usage.plan.provider] / 100
            })
            .reduce(getSum, 0);

        const indexState = selectedClient ? {
            ...state,
            setSelectedClient,
            selectedClient
        } : {
            ...props.state,
            promotionsLength: promotions.length,
            currentMonthPromotionUsage,
            lastMonthPromotionUsage,
            beforeLastMonthPromotionUsage,
            currentMonthRevenue,
            lastMonthRevenue,
            beforeLastMonthRevenue,
            setSelectedClient,
            totalUsage,
            selectedClient
        };

        setIndexState(indexState);
    }, [promotions, affiliateCommission, selectedClient, state, props.state]);
    
    function calculateAgentUsageAndRevenue() {
        const now = new Date();
        const beginningOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const beginningOfLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        const beginningOfBeforeLastMonth = new Date(now.getFullYear(), now.getMonth() - 2, 1);

        const currentMonthPayments = agentPurchases.filter(payment => {
            return new Date(payment?.createdAt) > beginningOfMonth;
        });
    
        const lastMonthPayments = agentPurchases.filter(payment => {
            return new Date(payment?.createdAt) > beginningOfLastMonth && new Date(payment?.createdAt) < beginningOfMonth;
        });
    
        const beforeLastMonthPayments = agentPurchases.filter(payment => {
            return new Date(payment?.createdAt) > beginningOfBeforeLastMonth && new Date(payment?.createdAt) < beginningOfLastMonth;
        });
    
        const getSum = (total, num) => total + num;
       
        const currentMonthRevenue = currentMonthPayments
            .map(payment => Number(payment.priceInUsd) * affiliateCommission["ESIMGO"] / 100)
            .reduce(getSum, 0);
    
        const lastMonthRevenue = lastMonthPayments
            .map(payment => Number(payment.priceInUsd) * affiliateCommission["ESIMGO"] / 100)
            .reduce(getSum, 0);
    
        const beforeLastMonthRevenue = beforeLastMonthPayments
            .map(payment => Number(payment.priceInUsd) * affiliateCommission["ESIMGO"] / 100)
            .reduce(getSum, 0);

        const indexState = selectedClient ? {
            ...state,
            setSelectedClient,
            selectedClient
        } : {
            ...props.state,
            currentMonthPaymentsCount: currentMonthPayments.length,
            currentMonthUsers: agentUsers.length,
            beforeLastMonthPayments,
            beforeLastMonthRevenue,
            currentMonthPayments,
            currentMonthRevenue,
            lastMonthPayments,
            setSelectedClient,
            lastMonthRevenue,
            selectedClient
        };
        setIndexState(indexState);
    }

    useEffect(()=> {
            if (isAgentLoggedIn){
                return calculateAgentUsageAndRevenue();
            }
    },[agentPurchases, promotions])

    useEffect(()=> {
        calculatePromotionUsageAndRevenue();
    },[calculatePromotionUsageAndRevenue])

    useEffect(
        () => {
            if (isAffiliateUserLoggedIn && !isAgentLoggedIn) {
                getAffiliateDetails();
                getAffiliateMarketerProfile();
            }else if (isAgentLoggedIn){
                getAffiliateMarketerProfile();
                getAgentDetails();
            } else {
                getUser();
                getSelectedClientStatistic()
            }
        }, [selectedClient]
    )

    return (
        <>
            {alertError()}
            <Header state={indexState} />
            {/* Page content */}
            {
                user?.isAdmin ?
                    <AdminDashboard
                        state={indexState}
                    />
                    : <Container className="mt--7" fluid>
                        <Row>
                            <Col className="mb-5 mb-xl-0" xl="8">
                                <Card className="bg-gradient-default shadow">
                                    <CardHeader className="bg-transparent">
                                        <Row className="align-items-center">
                                            <div className="col">
                                                <h6 className="text-uppercase text-light ls-1 mb-1">
                                                    Overview
                                                </h6>
                                                <h2 className="text-white mb-0">{isAffiliateUserLoggedIn ? "Revenue chart" : "Sales value"}</h2>
                                            </div>
                                            <div className="col">
                                                <Nav className="justify-content-end" pills>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames("py-2 px-3", {
                                                                active: activeNav === 1,
                                                            })}
                                                            href="#pablo"
                                                            onClick={(e) => toggleNavs(e, 1)}
                                                        >
                                                            <span className="d-none d-md-block">Month</span>
                                                            <span className="d-md-none">M</span>
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        {/* Chart */}
                                        <div className="chart">
                                            <Line
                                                data={isAffiliateUserLoggedIn ?
                                                    chartExample1([
                                                        Number(indexState.beforeLastMonthRevenue).toFixed(2),
                                                        Number(indexState.lastMonthRevenue).toFixed(2),
                                                        Number(indexState.currentMonthRevenue).toFixed(2),
                                                    ])[chartExample1Data]
                                                    :
                                                    chartExample1([
                                                        Number(props.state.beforeLastMonthSales).toFixed(2),
                                                        Number(props.state.lastMonthSales).toFixed(2),
                                                        Number(props.state.currentMonthSales).toFixed(2),
                                                    ])[chartExample1Data]
                                                }
                                                options={chartExample1.options}
                                                getDatasetAtEvent={(e) => console.log(e)}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl="4">
                                <Card className="shadow">
                                    <CardHeader className="bg-transparent">
                                        <Row className="align-items-center">
                                            <div className="col">
                                                <h6 className="text-uppercase text-muted ls-1 mb-1">
                                                    Performance
                                                </h6>
                                                <h2 className="mb-0">{isAffiliateUserLoggedIn && !isAgentLoggedIn ? 'Total Usage' : 'Total orders'}</h2>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="chart">
                                            <Bar
                                                data={isAffiliateUserLoggedIn ?
                                                    chartExample2([
                                                        isAgentLoggedIn ? indexState.beforeLastMonthPayments?.length :indexState.beforeLastMonthPromotionUsage?.length,
                                                        isAgentLoggedIn ? indexState.lastMonthPayments?.length : indexState.lastMonthPromotionUsage?.length,
                                                        isAgentLoggedIn ? indexState.currentMonthPayments?.length :indexState.currentMonthPromotionUsage?.length,
                                                    ]).data
                                                    :
                                                    chartExample2([
                                                        props.state.beforeLastMonthOrdersCount,
                                                        props.state.lastMonthOrdersCount,
                                                        props.state.currentMonthOrdersCount,
                                                    ]).data
                                                }
                                                options={chartExample2.options}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            {
                                isAffiliateUserLoggedIn ?
                                    isAgentLoggedIn ?
                                    <Col className="mb-5 mb-xl-0" xl="12">
                                        <Card className="shadow">
                                            <CardHeader className="border-0">
                                                <Row className="align-items-center">
                                                    <div className="col">
                                                        <h3 className="mb-0">Users</h3>
                                                    </div>
                                                    <div className="col text-right">
                                                        {/* <Link to='/orders' className='btn btn-primary text-white'>
											See all
										</Link> */}
                                                    </div>
                                                </Row>
                                            </CardHeader>
                                            <Table className="align-items-center table-flush" responsive>
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">First name</th>
                                                        <th scope="col">Last name</th>
                                                        <th scope="col">Device id</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {agentUsers.map((user) => {
                                                        return (
                                                            <tr>
                                                                <th scope="row">{user.email || "didn't create an account" }</th>
                                                                <td>{user.firstName || "-"}</td>
                                                                <td>{user.lastName || "-"}</td>
                                                                <td>{user.device?.id || "-"}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                        </Card>
                                    </Col> :
                                    <Col className="mb-5 mb-xl-0" xl="12">
                                    <Card className="shadow">
                                        <CardHeader className="border-0">
                                            <Row className="align-items-center">
                                                <div className="col">
                                                    <h3 className="mb-0">Promotion codes</h3>
                                                </div>
                                                <div className="col text-right">
                                                    {/* <Link to='/orders' className='btn btn-primary text-white'>
                                        See all
                                    </Link> */}
                                                </div>
                                            </Row>
                                        </CardHeader>
                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">Code</th>
                                                    <th scope="col">Type</th>
                                                    <th scope="col">Valid to</th>
                                                    <th scope="col">Usage</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {promotions.map((promotion) => {
                                                    return (
                                                        <tr>
                                                            <th scope="row">{promotion.code}</th>
                                                            <td>{promotion.type}</td>
                                                            <td>{promotion.validTo.split("T")[0]}</td>
                                                            <td>{promotion.consumption.length}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    </Card>
                                </Col>
                                    :
                                    <Col className="mb-5 mb-xl-0" xl="8">
                                        <Card className="shadow">
                                            <CardHeader className="border-0">
                                                <Row className="align-items-center">
                                                    <div className="col">
                                                        <h3 className="mb-0">Orders Summary</h3>
                                                    </div>
                                                    <div className="col text-right">
                                                        {/* <Link to='/orders' className='btn btn-primary text-white'>
											See all
										</Link> */}
                                                        <Button
                                                            color="primary"
                                                            href="#pablo"
                                                            onClick={(e) => e.preventDefault()}
                                                            size="sm"
                                                        >
                                                            See all
                                                        </Button>
                                                    </div>
                                                </Row>
                                            </CardHeader>
                                            <Table className="align-items-center table-flush" responsive>
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Provider</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {orders.slice(0, 5).map((order) => {
                                                        return (
                                                            <tr>
                                                                <th scope="row">{order.userEmail}</th>
                                                                <td>{order.provider}</td>
                                                                <td>{order.status}</td>
                                                                <td>{order.createdAt}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                        </Card>
                                    </Col>

                            }
                            {
                                !isAffiliateUserLoggedIn ?
                                    <Col xl="4">
                                        <Card className="shadow">
                                            <CardHeader className="border-0">
                                                <Row className="align-items-center">
                                                    <div className="col">
                                                        <h3 className="mb-0">eSIMs Summary</h3>
                                                    </div>
                                                    <div className="col text-right">
                                                        <Button
                                                            color="primary"
                                                            href="/orders"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                            }}
                                                            size="sm"
                                                        >
                                                            See all
                                                        </Button>
                                                    </div>
                                                </Row>
                                            </CardHeader>
                                            <Table className="align-items-center table-flush" responsive>
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Provider</th>
                                                        <th scope="col">Iccid</th>
                                                        <th scope="col">Qr code</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {orders.slice(0, 5).map((order) => {
                                                        return (
                                                            <tr>
                                                                <th scope="row">{order.userEmail}</th>
                                                                <td>{order.provider}</td>
                                                                <td>{order.output.iccid}</td>
                                                                <td>
                                                                    {order.links ? (
                                                                        <a
                                                                            href={order.links?.qrcode}
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            Visit
                                                                        </a>
                                                                    ) : (
                                                                        " ___ "
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                        </Card>
                                    </Col>
                                    : 
                                    isAgentLoggedIn ?
                                    <Col xl="12 mt-5">
                                        <Card className="shadow">
                                            <CardHeader className="border-0">
                                                <Row className="align-items-center">
                                                    <div className="col">
                                                        <h3 className="mb-0">Users Purchases</h3>
                                                    </div>

                                                </Row>
                                            </CardHeader>
                                            <Table className="align-items-center table-flush" responsive>
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th scope="col">User email</th>
                                                        <th scope="col">Plan name</th>
                                                        <th scope="col">Plan sold price</th>
                                                        <th scope="col">Plan Sold currency</th>
                                                        <th scope="col">Price in USD</th>
                                                        <th scope="col">Revenue in USD</th>
                                                        <th scope="col">Payment date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {agentPurchases.reverse().map((purchase) => {
                                                        if (!purchase) {
                                                            return;
                                                        }
                                                        return (
                                                            <tr>
                                                                <td>{purchase?.userEmail}</td>
                                                                <td>{purchase?.planName}</td>
                                                                <td>{purchase?.price}</td>
                                                                <td>{purchase?.currency}</td>
                                                                <td>{purchase?.priceInUsd}</td>
                                                                <td>{(purchase?.priceInUsd * 0.15).toFixed(2)}</td>
                                                                <td>{purchase?.createdAt?.split('T')[0]}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                        </Card>
                                    </Col>
                                    :
                                    <Col xl="12 mt-5">
                                        <Card className="shadow">
                                            <CardHeader className="border-0">
                                                <Row className="align-items-center">
                                                    <div className="col">
                                                        <h3 className="mb-0">Usages</h3>
                                                    </div>

                                                </Row>
                                            </CardHeader>
                                            <Table className="align-items-center table-flush" responsive>
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th scope="col">Plan name</th>
                                                        <th scope="col">Plan price</th>
                                                        <th scope="col">Date of usage</th>
                                                        <th scope="col">Revenue</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {indexState.totalUsage?.reverse().map((usage) => {
                                                            const commissions = {};
                                                            for (const commission of usage.affiliateCommissions) {
                                                                commissions[commission.provider] = commission.amount;
                                                            }
                                                        return (
                                                            <tr>
                                                                <td>{usage?.plan?.name}</td>
                                                                <td>{usage?.plan?.priceGbp}</td>
                                                                <td>{usage?.purchaseDate?.split('T')[0]}</td>
                                                                <td>{usage?.plan?.priceGbp * commissions[usage.plan.provider] / 100}£</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                        </Card>
                                    </Col>
                            }
                        </Row>
                    </Container>
            }
        </>
    );
};

