export const countryNameToIsoCode = {
    // Africa
    "Algeria": "DZ", "Angola": "AO", "Benin": "BJ", "Botswana": "BW", "Burkina Faso": "BF",
    "Burundi": "BI", "Cape Verde": "CV", "Cameroon": "CM", "Central African Republic": "CF", "Chad": "TD",
    "Comoros": "KM", "Congo (DRC)": "CD", "Congo (Republic)": "CG", "Côte d'Ivoire": "CI", "Djibouti": "DJ",
    "Egypt": "EG", "Equatorial Guinea": "GQ", "Eritrea": "ER", "Eswatini": "SZ", "Ethiopia": "ET",
    "Gabon": "GA", "Gambia": "GM", "Ghana": "GH", "Guinea": "GN", "Guinea-Bissau": "GW",
    "Kenya": "KE", "Lesotho": "LS", "Liberia": "LR", "Libya": "LY", "Madagascar": "MG",
    "Malawi": "MW", "Mali": "ML", "Mauritania": "MR", "Mauritius": "MU", "Mayotte": "YT",
    "Morocco": "MA", "Mozambique": "MZ", "Namibia": "NA", "Niger": "NE", "Nigeria": "NG",
    "Réunion": "RE", "Rwanda": "RW", "Saint Helena": "SH", "São Tomé and Príncipe": "ST", "Senegal": "SN",
    "Seychelles": "SC", "Sierra Leone": "SL", "Somalia": "SO", "South Africa": "ZA", "South Sudan": "SS",
    "Sudan": "SD", "Tanzania": "TZ", "Togo": "TG", "Tunisia": "TN", "Uganda": "UG",
    "Western Sahara": "EH", "Zambia": "ZM", "Zimbabwe": "ZW",

    // Asia
    "Afghanistan": "AF", "Armenia": "AM", "Azerbaijan": "AZ", "Bahrain": "BH", "Bangladesh": "BD",
    "Bhutan": "BT", "Brunei": "BN", "Cambodia": "KH", "China": "CN",
    "Georgia": "GE", "India": "IN", "Indonesia": "ID", "Iran": "IR", "Iraq": "IQ",
    "Israel": "IL", "Japan": "JP", "Jordan": "JO", "Kazakhstan": "KZ", "North Korea": "KP",
    "South Korea": "KR", "Kuwait": "KW", "Kyrgyzstan": "KG", "Laos": "LA", "Lebanon": "LB",
    "Macau": "MO", "Malaysia": "MY", "Maldives": "MV", "Mongolia": "MN", "Myanmar": "MM",
    "Nepal": "NP", "Oman": "OM", "Pakistan": "PK", "Palestine": "PS", "Philippines": "PH",
    "Qatar": "QA", "Saudi Arabia": "SA", "Singapore": "SG", "Sri Lanka": "LK", "Syria": "SY",
    "Taiwan": "TW", "Tajikistan": "TJ", "Thailand": "TH", "Timor-Leste": "TL", "Turkmenistan": "TM",
    "United Arab Emirates": "AE", "Uzbekistan": "UZ", "Vietnam": "VN", "Yemen": "YE", "Hong Kong": "HK",

    // Europe
    "Albania": "AL", "Andorra": "AD", "Austria": "AT", "Belarus": "BY", "Belgium": "BE",
    "Bosnia and Herzegovina": "BA", "Bulgaria": "BG", "Croatia": "HR", "Cyprus": "CY", "Czechia": "CZ",
    "Denmark": "DK", "Estonia": "EE", "Faroe Islands": "FO", "Finland": "FI", "France": "FR",
    "Germany": "DE", "Gibraltar": "GI", "Greece": "GR", "Hungary": "HU", "Iceland": "IS",
    "Ireland": "IE", "Italy": "IT", "Kosovo": "XK", "Latvia": "LV", "Liechtenstein": "LI",
    "Lithuania": "LT", "Luxembourg": "LU", "Malta": "MT", "Moldova": "MD", "Monaco": "MC",
    "Montenegro": "ME", "Netherlands": "NL", "North Macedonia": "MK", "Norway": "NO", "Poland": "PL",
    "Portugal": "PT", "Romania": "RO", "Russia": "RU", "San Marino": "SM", "Serbia": "RS",
    "Slovakia": "SK", "Slovenia": "SI", "Spain": "ES", "Svalbard and Jan Mayen": "SJ", "Sweden": "SE",
    "Switzerland": "CH", "Turkey": "TR", "Ukraine": "UA", "United Kingdom": "GB", "Vatican City": "VA",

    // Americas
    "Anguilla": "AI", "Antigua and Barbuda": "AG", "Argentina": "AR", "Aruba": "AW", "Bahamas": "BS",
    "Barbados": "BB", "Belize": "BZ", "Bermuda": "BM", "Bolivia": "BO", "Brazil": "BR",
    "British Virgin Islands": "VG", "Canada": "CA", "Cayman Islands": "KY", "Chile": "CL", "Colombia": "CO",
    "Costa Rica": "CR", "Cuba": "CU", "Curaçao": "CW", "Dominica": "DM", "Dominican Republic": "DO",
    "Ecuador": "EC", "El Salvador": "SV", "Falkland Islands": "FK", "French Guiana": "GF", "Greenland": "GL",
    "Grenada": "GD", "Guadeloupe": "GP", "Guatemala": "GT", "Guyana": "GY", "Haiti": "HT",
    "Honduras": "HN", "Jamaica": "JM", "Martinique": "MQ", "Mexico": "MX", "Montserrat": "MS",
    "Nicaragua": "NI", "Panama": "PA", "Paraguay": "PY", "Peru": "PE", "Puerto Rico": "PR",
    "Saint Barthélemy": "BL", "Saint Kitts and Nevis": "KN", "Saint Lucia": "LC", "Saint Martin (French part)": "MF", "Saint Vincent and the Grenadines": "VC",
    "Sint Maarten (Dutch part)": "SX", "Suriname": "SR", "Trinidad and Tobago": "TT", "Turks and Caicos Islands": "TC", "United States": "US",
    "Uruguay": "UY", "Venezuela": "VE",

    // Oceania
    "American Samoa": "AS", "Australia": "AU", "Cook Islands": "CK", "Fiji": "FJ", "French Polynesia": "PF",
    "Guam": "GU", "Kiribati": "KI", "Marshall Islands": "MH", "Micronesia": "FM", "Nauru": "NR",
    "New Caledonia": "NC", "New Zealand": "NZ", "Niue": "NU", "Norfolk Island": "NF", "Northern Mariana Islands": "MP",
    "Palau": "PW", "Papua New Guinea": "PG", "Pitcairn Islands": "PN", "Samoa": "WS", "Solomon Islands": "SB",
    "Tokelau": "TK", "Tonga": "TO", "Tuvalu": "TV", "United States Minor Outlying Islands": "UM", "Vanuatu": "VU",
    "Wallis and Futuna": "WF"
};
