import authApiConfig from '../config/authConfig';

const refreshToken = (clientId: string, refreshToken: string, grantType: any) => {
	const route = '/tokens';
	const body = { clientId, refreshToken, grantType };
	return authApiConfig.post(route, body);
};

const login = (email: string, password: string) => {
	const route = '/dashboard-login';
	const body = { email, password };
	return authApiConfig.post(route, body);
};

export const authApi = {
	refreshToken,
	login,
};
