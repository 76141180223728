import { useState, useEffect } from "react";
import { Button, Container, Form, ListGroup, } from "react-bootstrap";
import { PropagateLoader } from "react-spinners";
import { UncontrolledAlert } from "reactstrap";
import { apiEndpoints } from "services/api/endpoints/apiEndpoints";
import { betaApi } from "services/api/endpoints/betaApiEndpoints";
import { IClient } from "types/interfaces";

const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};

export const AdminDashboard = (props: any) => {
    const [isLoading, setIsloading] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [clients, setClients] = useState<IClient[]>([]);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const totalPages = Math.ceil(clients.length / pageSize);
    const startIndex = (page - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, clients.length);
    const visibleClients: IClient[] = clients.slice(startIndex, endIndex);

    const getClients = async () => {
        const response = await apiEndpoints.getAllClients();
        setClients(response.data.clients);
    }

    const handleDownloadInvoice = async (client: any) => {
        try {
            if (!startDate && !endDate) {
                setErrorMsg("Please set a time range!");
                return
            }
            setIsloading(true)
            const response = await apiEndpoints.getClientBillingCsv(
                {
                    startDate,
                    endDate,
                    clientId: client.clientId
                }
            )
            const blob = new Blob([response.data.csvContent.csvContent], { type: 'text/csv' });
            const fileName = `${client.clientId}-${startDate}-${endDate}.csv`;
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.href = url;
            a.download = fileName;
            a.click();
            setIsloading(false)
        } catch (error: any) {
            setErrorMsg('Something went wrong please try again later')
            setIsloading(false)
        }
    };

    const handleDownloadCouponProviderInvoice = async () => {
        try {
            if (!startDate && !endDate) {
                setErrorMsg("Please set a time range!");
                return
            }
            setIsloading(true)
            const response = await betaApi.getCouponProviderBilling(
                {
                    startDate,
                    endDate,
                    couponProviderId: process.env.REACT_APP_PERKBOX_ID
                }
            )
            const blob = new Blob([response.data], { type: 'text/csv' });
            const fileName = `PerkBox-${startDate}-${endDate}.csv`;
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.href = url;
            a.download = fileName;
            a.click();
            setIsloading(false)
        } catch (error: any) {
            console.log("error: ", error);

            setErrorMsg('Something went wrong please try again later')
            setIsloading(false)
        }

    };

    const handleClientSelection = (client: IClient) => {
        props.state.setSelectedClient(client);
    };

    const handlePageChange = (pageNumber: number) => {
        setPage(pageNumber);
    };

    function alertError() {
        if (errorMsg) {
            return (
                <UncontrolledAlert className='alert-default mt-5' fade={false}>
                    <span className='alert-inner--text'>{errorMsg}</span>
                </UncontrolledAlert>
            );
        }
    }


    useEffect(
        () => {
            getClients();
        }, []
    )


    return <Container>
        {alertError()}
        {
            isLoading && <div className="loader" style={{ marginTop: 40, marginBottom: 0 }}>
                <PropagateLoader
                    color={"#8965e0"}
                    loading={isLoading}
                    cssOverride={override}
                    size={20}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div>
        }
        <Form className="d-flex justify-content-center align-items-center mt-5 mb-5">
            <Form.Group className="mx-3">
                <Form.Label>Filter invoices by date range:</Form.Label>
            </Form.Group>
            <Form.Group className="mx-3">
                <Form.Control
                    type="date"
                    value={startDate?.substr(0, 10)}
                    onChange={(e) => setStartDate(new Date(e.target.value).toISOString())}
                />
            </Form.Group>
            <Form.Group className="mx-3">
                <Form.Label>to</Form.Label>
            </Form.Group>
            <Form.Group className="mx-3">
                <Form.Control
                    type="date"
                    value={endDate?.substr(0, 10)}
                    onChange={(e) => setEndDate(new Date(e.target.value).toISOString())}
                />
            </Form.Group>
        </Form>
        <ListGroup>
            {visibleClients.map((client: IClient) => (
                <>
                    <ListGroup.Item
                        key={client._id}
                        className="d-flex justify-content-between align-items-center mb-2"
                        onClick={() => handleClientSelection(client)}
                    >
                        <div>
                            <h2>{client.name}</h2>
                        </div>
                        <div>
                            <Button
                                variant="primary"
                                onClick={() => handleDownloadInvoice(client)}
                            >
                                Create & Download Billing
                            </Button>
                        </div>
                    </ListGroup.Item>
                    {props.state.selectedClient?._id === client._id && (
                        <div className="mb-4">
                            <ListGroup.Item
                                key={client._id}
                                className={"bg-light"}
                            >
                                <div>
                                    <p><strong>Client ID: </strong> {client?.clientId}</p>
                                    <p><strong>Client email: </strong> {client.email}</p>
                                    <p><strong>Joining date: </strong> {client.createdAt.split('T')[0]}</p>
                                </div>
                            </ListGroup.Item>
                        </div>
                    )}
                </>
            ))}
            <ListGroup.Item
                key="PerkBox"
                className="d-flex justify-content-between align-items-center mb-2"
                onClick={() => handleDownloadCouponProviderInvoice()}
            >
                <div>
                    <h2>PerkBox</h2>
                </div>
                <div>
                    <Button
                        variant="primary"
                        onClick={() => handleDownloadCouponProviderInvoice()}
                    >
                        Create & Download Billing
                    </Button>
                </div>
            </ListGroup.Item>
        </ListGroup>
        <nav>
            <ul className="pagination mt-5">
                {[...Array(totalPages)].map((_, i) => (
                    <li
                        key={i}
                        className={`page-item ${page === i + 1 ? "active" : ""}`}
                    >
                        <a
                            className="page-link"
                            onClick={() => handlePageChange(i + 1)}
                            href="#"
                        >
                            {i + 1}
                        </a>
                    </li>
                ))}
            </ul>
        </nav>
    </Container>
};
