import { useState, useEffect, useCallback } from 'react';
import {
	Table,
	Button,
	UncontrolledAlert,
} from 'reactstrap';
import { Form } from "react-bootstrap";

import Header from 'components/Headers/Header';
import PropagateLoader from "react-spinners/PropagateLoader";
import { betaApi } from 'services/api/endpoints/betaApiEndpoints';

const override = {
	display: "block",
	margin: "0 auto",
	borderColor: "red",
};


interface Props {
	state: object;
}

export const Affiliate = (props: Props) => {
	const [isLoading, setIsloading] = useState<boolean>(true);
	const [affiliateDetails, setAffiliateDetails] = useState<any>([]);
	const [renderAffiliateDetails, setRenderAffiliateDetails] = useState<any>([]);
	const [startDate, setStartDate] = useState<string>("");
	const [endDate, setEndDate] = useState<string>("");
	const [errorMsg, setErrorMsg] = useState<string>();

	const getAffiliationStatistics = useCallback(async () => {
		try {
			const response = await betaApi.getAffiliationStatistics();
			setAffiliateDetails(response.data);
			setRenderAffiliateDetails(response.data);
			setIsloading(false)
		} catch (error) {
			setErrorMsg('Failed to fetch affiliation details');
			setIsloading(false);
		}
	}, [setAffiliateDetails])


	const getCommission = (affiliateCommissions: any, provider: string): number => {
		const commission = affiliateCommissions?.find((current: any) => current.provider === provider);
		return commission ? commission.amount / 100 : 0;
	}


	const isDateInCurrentTimeFrame = (dateStr: string, fromDateStr: string, toDateStr: string) => {
		const dateToCheck = new Date(dateStr);
		const fromDate = new Date(fromDateStr);
		const toDate = new Date(toDateStr);

		return dateToCheck >= fromDate && dateToCheck <= toDate;
	};


	const getReportBasedOnTimeFrame = useCallback(() => {
		try {
			if (!startDate || !endDate || startDate > endDate) {
				setErrorMsg('Please enter a valid date range');
				return
			}
			const response = [];
			for (const current of affiliateDetails) {
				const currentMonthConsumptions = current.consumption.filter((currentConsumption: any) =>
					isDateInCurrentTimeFrame(currentConsumption.purchaseDate, startDate, endDate));
				const currentMonthRevenue = currentMonthConsumptions.reduce((accumulator: any, currentItem: any) => {
					return accumulator + currentItem.plan.priceGbp * getCommission(current.affiliateCommissions, currentItem.plan.provider);
				}, 0);
				const currentMonthZimRevenue = currentMonthConsumptions.reduce((accumulator: any, currentItem: any) => {
					return accumulator + currentItem.plan.priceGbp;
				}, 0);
				response.push({ ...current, currentMonthConsumptions, currentMonthRevenue, currentMonthZimRevenue });
			}
			setRenderAffiliateDetails(response);
		} catch (error) {
		}
	}, [startDate, endDate])


	function alertError() {
		if (errorMsg) {
			return (
				<UncontrolledAlert className='alert-default' fade={false}>
					<span className='alert-inner--text'>{errorMsg}</span>
				</UncontrolledAlert>
			);
		}
	}


	useEffect(() => {
		getAffiliationStatistics();
	}, [])


	return (
		<>
			<Header state={props.state} />
			{
				isLoading
					?
					<div className="loader">
						<PropagateLoader
							color={"#8965e0"}
							loading={isLoading}
							cssOverride={override}
							size={40}
							aria-label="Loading Spinner"
							data-testid="loader"
						/>
					</div>
					:
					<>
						{alertError()}
						<Form className="d-flex justify-content-center align-items-center mt-5 mb-5">
							<Form.Group className="mx-3">
								<Form.Label>Filter data by date range:</Form.Label>
							</Form.Group>
							<Form.Group className="mx-3">
								<Form.Control
									type="date"
									value={startDate?.substr(0, 10)}
									onChange={(e) => setStartDate(new Date(e.target.value).toISOString())}
								/>
							</Form.Group>
							<Form.Group className="mx-3">
								<Form.Label>to</Form.Label>
							</Form.Group>
							<Form.Group className="mx-3">
								<Form.Control
									type="date"
									value={endDate?.substr(0, 10)}
									onChange={(e) => setEndDate(new Date(e.target.value).toISOString())}
								/>
							</Form.Group>
							<Form.Group className="mx-3">
								<Button onClick={getReportBasedOnTimeFrame}>Filter</Button>
							</Form.Group>
						</Form>
						<Table className="align-items-center table-flush" responsive>
							<thead className="thead-light">
								<tr>
									<th scope="col">promotion</th>
									<th scope="col">status</th>
									<th scope="col">Zim revenue</th>
									<th scope="col">Revenue</th>
									<th scope="col">Consumptions</th>
									<th scope="col">Valid until</th>
								</tr>
							</thead>
							<tbody>
								{renderAffiliateDetails.map((affiliate: any) => {
									return (
										<tr>
											<td>{affiliate.code}</td>
											<td>{affiliate.active ? "Active" : "Inactive"}</td>
											<td>{affiliate.currentMonthZimRevenue.toFixed(2)}</td>
											<td>{affiliate.currentMonthRevenue.toFixed(2)}</td>
											<td>{affiliate.currentMonthConsumptions.length}</td>
											<td>{affiliate.validTo.split('T')[0]}</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</>
			}
		</>
	)
};
