import { useState, useEffect, useRef } from 'react';
import {
	Card,
	CardHeader,
	CardFooter,
	Table,
	Container,
	Row,
	UncontrolledAlert,
} from 'reactstrap';
import Header from 'components/Headers/Header';
import { apiEndpoints } from '../../services/api/endpoints/apiEndpoints';
import Pagination from '../../components/Pagination/Pagination';
import { IZIMEsim } from '../../types/interfaces';
import PropagateLoader from "react-spinners/PropagateLoader";
import { Provider } from 'types/enums';

interface Props {
	state: object;
}

const override = {
	display: "block",
	margin: "0 auto",
	borderColor: "red",
};

export const Esims = (props: Props) => {
	const [eSims, seteSims] = useState<IZIMEsim[]>();
	const [page, setPage] = useState(1);
	const [errorMsg, setErrorMsg] = useState<string>();
	const allPages = useRef<number[]>([]);
	const itemsPerPage = useRef(5);
	const [isLoading, setIsloading] = useState<boolean>(true);

	function alertError() {
		if (errorMsg) {
			return (
				<UncontrolledAlert className='alert-default' fade={false}>
					<span className='alert-inner--text'>{errorMsg}</span>
				</UncontrolledAlert>
			);
		}
	}

	useEffect(
		() => {
			async function getEsims() {
				try {
					setIsloading(true)
					setErrorMsg('');
					const params = {
						pageSize: itemsPerPage.current,
						page,
					};
					const response = await apiEndpoints.fetchClientEsims(params);
					const pages = [];
					for (
						let i = 1;
						i <= Math.ceil(response.data.total / itemsPerPage.current);
						i++
					) {
						pages.push(i);
					}
					allPages.current = pages;
					seteSims(response.data.data);
					setPage(response.data.page);
					setIsloading(false)
				} catch (error: any) {
					setErrorMsg(error.response.data?.message);
				}
			}
			getEsims();
		},
		[page]
	);

	const isAramco = process.env.REACT_APP_ARAMCO_CLIENT_ID;

	return (
		<>
			<Header state={props.state} />
			{/* Page content */}
			{
				isLoading
					?
					<div className="loader">
						<PropagateLoader
							color={"#8965e0"}
							loading={isLoading}
							cssOverride={override}
							size={40}
							aria-label="Loading Spinner"
							data-testid="loader"
						/>
					</div>
					:
					<Container className='mt--7' fluid>
						{/* Table */}
						{alertError()}
						<Row>
							<div className='col'>
								<Card className='shadow'>
									<CardHeader className='border-0'>
										<h3 className='mb-0'>eSIMs</h3>
									</CardHeader>
									<Table className='align-items-center table-flush' style={{ textAlign: "center" }} responsive>
										<thead className='thead-light'>
											<tr>
												{!isAramco && <th scope='col'>EMAIL</th>}
												<th scope='col'>PROVIDER</th>
												<th scope='col'>MATCHING ID</th>
												<th scope='col'>ICCID</th>
												<th scope='col'>Phone number</th>
												<th scope='col'>STATUS</th>
												<th scope='col'>BALANCE CHECK DIAL</th>
												<th scope='col'>QR CODE</th>
												{/* <th scope='col' /> */}
											</tr>
										</thead>
										<tbody>
											{
												eSims?.map(
													(esim: IZIMEsim) => {
														return (
															<tr>
																{!isAramco && <th scope='row'>{esim.userEmail}</th>}
																<td>{esim.provider}</td>
																<td>{esim.matchingId}</td>
																<td>{esim.iccid}</td>
																<td>{esim.msisdn && esim.provider === Provider.ORANGE ? esim.countryCode + esim.msisdn : " ___ "}</td>
																<td>{esim.status}</td>
																<td>{esim.provider === Provider.ORANGE ? "#123#" : " ___ "}</td>

																<td>
																	{esim.links ? (
																		<a
																			href={esim.links?.qrcode}
																			target="_blank"
																			rel="noreferrer"
																		>
																			Visit
																		</a>
																	) : (
																		" ___ "
																	)}
																</td>

															</tr>
														);
													}
												)
											}
										</tbody>
									</Table>
									<CardFooter className='py-4'>
										<nav aria-label='...'>
											<Pagination
												allPages={allPages.current}
												itemsPerPage={itemsPerPage.current}
												page={page}
												setPage={setPage}
											/>
										</nav>
									</CardFooter>
								</Card>
							</div>
						</Row>
					</Container>
			}
		</>
	);
};
