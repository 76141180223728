import React from "react";
import { LocationDropdown } from "./LocationDropdown";
import { Col, Form, Row } from "react-bootstrap";

interface CheckboxQuestion {
    title: string;
    label: string;
}

interface EndUserIssueEscalationFormProps {
    handleSubmit: (e: any) => void;
    handleInputChange: (e: any) => void;
    handleLocationChange: (e: any) => void;
    checkboxQuestions: CheckboxQuestion[];
    locationLabel: string;
}

export const EndUserIssueEscalationForm: React.FC<
    EndUserIssueEscalationFormProps
> = (
    {
        handleSubmit,
        handleInputChange,
        handleLocationChange,
        checkboxQuestions,
        locationLabel,
    }
) => {
        return <form className="mt-5" onSubmit={handleSubmit}>
            <div className="form-group">
                <label htmlFor="subject">Subject</label>
                <input
                    type="text"
                    className="form-control"
                    id="subject"
                    name="subject"
                    placeholder="Enter subject"
                    required
                    onChange={handleInputChange}
                />
            </div>
            <div className="form-group">
                <label htmlFor="iccid">eSIM ICCID</label>
                <input
                    type="text"
                    className="form-control"
                    id="iccid"
                    name="iccid"
                    placeholder="eSIM ICCID/Serial Number (begins with 89...)"
                    required
                    onChange={handleInputChange}
                />
            </div>
            <div className="form-group">
                <label htmlFor="issueDescription">Description</label>
                <textarea
                    className="form-control"
                    id="issueDescription"
                    name="issueDescription"
                    rows={3}
                    placeholder="Please enter the further details of your request. A member of our support staff will respond as soon as possible."
                    required
                    onChange={handleInputChange}
                />
            </div>
            <LocationDropdown label={locationLabel} onChange={handleLocationChange} />
            <Form.Group as={Row} controlId="issuePriority">
                <Form.Label column sm={3}>
                    Priority:
                </Form.Label>
                <Col sm={12}>
                    <select
                        className="form-control mb-4"
                        id="issuePriority"
                        name="issuePriority"
                        onChange={handleInputChange}
                    >
                        <option value="low"> - </option>
                        <option value="low">Low</option>
                        <option value="normal">Normal</option>
                        <option value="high">High</option>
                        <option value="urgent">Urgent</option>
                    </select>
                </Col>
            </Form.Group>
            {checkboxQuestions.map((question: CheckboxQuestion) => (
                <div className="form-check mb-3" key={question.label}>
                    <strong>{question.title} ?</strong> <br />
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id={question.label}
                        name={question.label}
                        required
                    />
                    <label className="form-check-label" htmlFor={question.label}>
                        {question.label}
                    </label>
                </div>
            ))}
            <button type="submit" className="btn btn-primary">
                Submit
            </button>
        </form>
    };
