import { useState, useEffect } from "react";
import {
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input,
	Container,
	Row,
	Col,
	Button,
	UncontrolledAlert
} from "reactstrap";
import UserHeader from "components/Headers/UserHeader";
import { useStateContext } from '../../contexts/contextProvider';
import { apiEndpoints } from "services/api/endpoints/apiEndpoints";

export const Profile = () => {
	const { user, setUser } = useStateContext();
	const [userProfile, setUserProfile] = useState({
		oldPassword: undefined,
		password: undefined,
		confirmPassword: undefined,
		address: user.address,
		city: user.city,
		country: user.country,
		zip: user.zip,
	});
	const [errorMsg, setErrorMsg] = useState("");
	const isAffiliateUserLoggedIn = localStorage.getItem('isAffiliateUserLoggedIn');

	const getUser = async () => {
		try {
			if (!isAffiliateUserLoggedIn) {
				const response = await Promise.any(
					[
						apiEndpoints.getAdmin(),
						apiEndpoints.getClient(),
					]
				)
				setUser(response.data.client ? response.data.client : { ...response.data.admin, isAdmin: true });
			}
		} catch (error) { }
	}

	function collectData(e: any) {
		setUserProfile({
			...userProfile,
			[e.target.name.trim()]: e.target.value.trim(),
		});
	}

	async function handleSubmit(e: any) {
		e.preventDefault();
		try {
			if (userProfile.password !== userProfile.confirmPassword) {
				return setErrorMsg("New passwords are not matching");
			}
			await apiEndpoints.updateClientProfile({ ...userProfile, confirmPassword: undefined });
			setErrorMsg("Profile updated successfully");
		} catch (error: any) {
			setErrorMsg(error.response.data?.message);
		}
	}

	function alertError() {
		if (errorMsg) {
			return (
				<UncontrolledAlert className='alert-default mt-3' fade={false}>
					<span className='alert-inner--text'>{errorMsg}</span>
				</UncontrolledAlert>
			);
		}
	}

	useEffect(() => {
		getUser()
	}, [userProfile])

	return (
		<>
			<UserHeader user={user} />
			{/* Page content */}
			<Container className="mt--7" fluid>
				<Row>
					<Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
						<Card className="card-profile shadow">
							<Row className="justify-content-center">
								<Col className="order-lg-2" lg="3">
									<div className="card-profile-image">
										<a href="#pablo" onClick={(e) => e.preventDefault()}>
											<img
												alt="..."
												className="rounded-circle"
												src={require("../../assets/img/theme/team-4-800x800.jpg")}
											/>
										</a>
									</div>
								</Col>
							</Row>
							<CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
							</CardHeader>
							<CardBody className="mt-5 pt-md-5">

								<div className="text-center">
									<h3>
										{isAffiliateUserLoggedIn ? user?.firstName : user.name}
									</h3>
									<div className="h5 font-weight-300">
										<i className="ni location_pin mr-2" />
										ZIM Connection Client
									</div>
									<div className="h5 mt-4">
										<i className="ni business_briefcase-24 mr-2" />
									</div>
								</div>
							</CardBody>
						</Card>
					</Col>
					<Col className="order-xl-1" xl="8">
						<Card className="bg-secondary shadow">
							<CardHeader className="bg-white border-0">
								<Row className="align-items-center">
									<Col xs="8">
										<h3 className="mb-0">My account</h3>
									</Col>

								</Row>
							</CardHeader>
							<CardBody>
								<Form>
									<h6 className="heading-small text-muted mb-4">
										User information
									</h6>
									<div className="pl-lg-4">
										<Row>
											<Col lg="6">
												<FormGroup>
													<label
														className="form-control-label"
														htmlFor="input-username"
													>
														Name
													</label>
													<Input
														className="form-control-alternative"
														defaultValue="zim.client"
														id="input-username"
														placeholder="Username"
														type="text"
														value={isAffiliateUserLoggedIn ? user?.firstName : user.name}
													/>
												</FormGroup>
											</Col>
											<Col lg="6">
												<FormGroup>
													<label
														className="form-control-label"
														htmlFor="input-email"
													>
														Email address
													</label>
													<Input
														className="form-control-alternative"
														id="input-email"
														placeholder="zim.client@example.com"
														type="email"
														value={isAffiliateUserLoggedIn ? user?.email : user?.email}
													/>
												</FormGroup>
											</Col>
											<Col lg="12">
												<FormGroup>
													<label
														className="form-control-label"
														htmlFor="input-email"
													>
														Client Id
													</label>
													<Input
														className="form-control-alternative"
														id="input-text"
														placeholder="xxxx-xxxx-xxxx-xxxx"
														type="text"
														value={isAffiliateUserLoggedIn ? " - " : user?.clientId}
													/>
												</FormGroup>
											</Col>
										</Row>
									</div>
									<hr className="my-4" />
									{/* Address */}
									{
										!isAffiliateUserLoggedIn && <>
											<h6 className="heading-small text-muted mb-4">
												Contact information
											</h6>
											<div className="pl-lg-4">
												<Row>
													<Col md="12">
														<FormGroup>
															<label
																className="form-control-label"
																htmlFor="input-address"
															>
																Address
															</label>
															<Input
																className="form-control-alternative"
																defaultValue={user.address}
																id="input-address"
																name="address"
																placeholder="Home Address"
																type="text"
																onChange={collectData}
															/>
														</FormGroup>
													</Col>
												</Row>
												<Row>
													<Col lg="4">
														<FormGroup>
															<label
																className="form-control-label"
																htmlFor="input-city"
															>
																City
															</label>
															<Input
																className="form-control-alternative"
																defaultValue={user.city}
																id="input-city"
																name="city"
																placeholder="City"
																type="text"
																onChange={collectData}
															/>
														</FormGroup>
													</Col>
													<Col lg="4">
														<FormGroup>
															<label
																className="form-control-label"
																htmlFor="input-country"
															>
																Country
															</label>
															<Input
																className="form-control-alternative"
																defaultValue={user.country}
																id="input-country"
																name="country"
																placeholder="Country"
																type="text"
																onChange={collectData}
															/>
														</FormGroup>
													</Col>
													<Col lg="4">
														<FormGroup>
															<label
																className="form-control-label"
																htmlFor="input-country"
															>
																Postal code
															</label>
															<Input
																className="form-control-alternative"
																defaultValue={user.zip}
																id="input-postal-code"
																name="zip"
																placeholder="Postal code"
																type="text"
																onChange={collectData}
															/>
														</FormGroup>
													</Col>
												</Row>
											</div>
											<hr className="my-4" />
											<h6 className="heading-small text-muted mb-4">
												Change Password
											</h6>
											<div className="pl-lg-4">
												<Row>
													<Col lg="4">
														<FormGroup>
															<label
																className="form-control-label"
																htmlFor="input-city"
															>
																Old Password
															</label>
															<Input
																className="form-control-alternative"
																id="old-password"
																name="oldPassword"
																placeholder="oldPassword"
																type="password"
																onChange={collectData}
															/>
														</FormGroup>
													</Col>
													<Col lg="4">
														<FormGroup>
															<label
																className="form-control-label"
																htmlFor="input-country"
															>
																New Password
															</label>
															<Input
																className="form-control-alternative"
																id="password"
																name="password"
																placeholder="New Password"
																type="password"
																onChange={collectData}
															/>
														</FormGroup>
													</Col>
													<Col lg="4">
														<FormGroup>
															<label
																className="form-control-label"
																htmlFor="input-country"
															>
																New Password
															</label>
															<Input
																className="form-control-alternative"
																id="password"
																name="confirmPassword"
																placeholder="Confirm new Password"
																type="password"
																onChange={collectData}
															/>
														</FormGroup>
													</Col>
												</Row>
											</div>
											<hr className="my-4" />
										</>
									}
								</Form>
							</CardBody>
						</Card>
					</Col>
				</Row>
				{
					!isAffiliateUserLoggedIn &&
					<>
						<Button className='my-4' color='primary' type='submit' onClick={handleSubmit}>
							Submit
						</Button>
						{alertError()}
					</>
				}

			</Container>
		</>
	);
};
